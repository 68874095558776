import React, { FC, useCallback, useState } from "react";
import { Box, Typography } from "@mui/material";
import { DropzoneOptions, FileRejection, useDropzone } from "react-dropzone";
import { grey } from "@mui/material/colors";
import { toast } from "react-toastify";
import theme from "src/configs/theme";
import uploadSvg from "src/assets/icons/upload_file.svg";
import { UploadFileCard } from "./UploadFileCard";
import { getVideoDuration } from "./utils";

const supportedDuration = 5; // in minutes
const supportedSize = 400; // in MB

type DropzonePropsType = {
  dropzoneOptions?: DropzoneOptions;
  onChange: (files: File[]) => void;
  dropzoneText?: string;
  multiple?: boolean;
  fileUploadStates?: FileUploadState[];
  onCancel?: (index: number) => void;
};

type FileUploadState = {
  file: File;
  progress: number;
  status: "uploading" | "success" | "error";
  abortController: AbortController;
};

const Dropzone: FC<DropzonePropsType> = ({
  dropzoneOptions = {
    maxSize: supportedSize * 1024 * 1024,
    accept: {
      "video/*": [
        ".mp4",
        ".mkv",
        ".webm",
        ".ogg",
        ".avi",
        ".mov",
        ".wmv",
        ".m4v",
      ],
    },
  },
  onChange,
  dropzoneText,
  multiple = false,
  fileUploadStates = [],
  onCancel,
}) => {
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);

  const onDrop = useCallback(
    (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      handleFileRejections(fileRejections);

      const validFilesPromises = acceptedFiles.map((file) =>
        getVideoDuration(file).then((duration) => {
          if (Math.round(duration) >= 60 * supportedDuration + 10) {
            toast.error(
              `Selected file length is more than ${supportedDuration} minutes`,
            );
            return null;
          } else {
            return file;
          }
        }),
      );

      Promise.all(validFilesPromises).then((validFiles) => {
        const nonNullFiles = validFiles.filter(
          (file) => file !== null,
        ) as File[];
        setSelectedFiles((prevFiles) =>
          multiple ? [...prevFiles, ...nonNullFiles] : nonNullFiles,
        );
        onChange(multiple ? [...selectedFiles, ...nonNullFiles] : nonNullFiles);
      });
    },
    [multiple, onChange, selectedFiles],
  );

  const handleFileRejections = (fileRejections: FileRejection[]) => {
    fileRejections.forEach((file) => {
      file.errors.forEach((err) => {
        if (err.code === "file-too-large") {
          toast.error(`Error: File is larger than ${supportedSize} MB`);
        }
        if (err.code === "file-invalid-type") {
          toast.error(`Error: ${err.message}`);
        }
      });
    });
  };

  const removeFileHandler = (indexToRemove: number) => {
    setSelectedFiles((prevFiles) =>
      prevFiles.filter((_, index) => index !== indexToRemove),
    );
    onChange(selectedFiles.filter((_, index) => index !== indexToRemove));
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    ...dropzoneOptions,
    multiple,
  });

  const dropzoneContent = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <img src={uploadSvg} alt="upload_dropzone" />
      <Typography
        sx={{ color: grey[400] }}
        fontWeight="bold"
        align="center"
        lineHeight="2rem"
      >
        {dropzoneText ||
          `Drag and Drop ${
            multiple ? "video files" : "a video file"
          } here or click to select`}
      </Typography>
      <Typography fontWeight="bold" align="center" sx={{ color: grey[400] }}>
        {`* Supported file duration is less than ${supportedDuration} minute${
          supportedDuration > 1 ? "s" : ""
        }`}
      </Typography>
      <Typography fontWeight="bold" align="center" sx={{ color: grey[400] }}>
        {`* Supported file size is less than ${supportedSize} MB`}
      </Typography>
      <Typography fontWeight="bold" sx={{ color: grey[400] }} align="center">
        * Supported file types: .mp4, .mkv, .webm, .ogg, .avi, .mov, .wmv, .m4v
      </Typography>
    </Box>
  );

  return (
    <Box
      sx={{
        width: "100%",
        border: "1px dashed rgba(0, 0, 0, 0.5)",
        borderRadius: theme.spacing(1),
        padding: theme.spacing(4, 2),
        cursor: "pointer",
      }}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      {(selectedFiles.length === 0 || multiple) && dropzoneContent}
      <Box mt={2}>
        {selectedFiles.map((file, index) => (
          <UploadFileCard
            key={index}
            file={file}
            progress={fileUploadStates[index]?.progress || 0}
            status={fileUploadStates[index]?.status || "uploading"}
            cancel={() => {
              removeFileHandler(index);
              onCancel && onCancel(index);
            }}
          />
        ))}
      </Box>
    </Box>
  );
};

export default Dropzone;
