import { FC } from "react";
import { Grid } from "@mui/material";
import AdvanceOptions from "../AdvanceOptions";
import { FormDataType } from "../Single/types";

interface AdvanceStepProps {
  values: AdvanceOptionsValues;
  onValuesChange: (values: AdvanceOptionsValues) => void;
}

interface AdvanceOptionsValues {
  blurFace: boolean;
  blurBack: boolean;
  multiTarget: boolean;
  useCameraZ: boolean;
}

const AdvanceStep: FC<AdvanceStepProps> = ({ values, onValuesChange }) => {
  const handleToggle = (key: keyof FormDataType, value: boolean) => {
    onValuesChange({ ...values, [key]: value });
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <AdvanceOptions {...values} onToggle={handleToggle} />
      </Grid>
    </Grid>
  );
};

export default AdvanceStep;
