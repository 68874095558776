import { FC, useState, useRef } from "react";
import { Button, Divider, Grid, TextField, Typography } from "@mui/material";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import { AxiosProgressEvent } from "axios";

import Dropzone from "../Dropzone";
import AutocompleteWithAdd, {
  nameIdType,
} from "src/components/atoms/AutocompleteWithAdd";
import { UploadProcessDialog } from "src/components/organisms/addVideo/Single/UploadProcessDialog";

import { useCustomMediaControllerUploadVideoMutation } from "src/app/services/api";
import { CategoriesRo, TagRo } from "src/app/services/generatedApi";

import CategorySelect from "../CategorySelect";
import AdvanceOptions from "../AdvanceOptions";

import { FormDataType, UploadProcessEvent } from "./types";
import { useFormData } from "./useFormData";
import { useFileUpload } from "./useFileUpload";
import { tagModifier } from "../tagModifier";

const SingleVideoUpload: FC<{
  allCategories?: CategoriesRo;
  allTags?: TagRo[];
}> = ({ allCategories, allTags }) => {
  const { formData, updateFormData, isFormValid } = useFormData(allCategories);
  const [createTask] = useCustomMediaControllerUploadVideoMutation();
  const [uploadProcessEvent, setUploadProcessEvent] =
    useState<UploadProcessEvent>();
  const abortController = useRef<AbortController>();
  const navigate = useNavigate();

  const {
    fileUploadState,
    setFileUploadState,
    handleFileChange,
    handleCancel,
  } = useFileUpload(updateFormData);

  const handleSubmit = async () => {
    if (!isFormValid) return;

    const formDataToSend = new FormData();
    Object.entries(formData).forEach(([key, value]) => {
      if (key === "tagName") {
        formDataToSend.append(
          key,
          tagModifier(value as string | nameIdType | null),
        );
      } else {
        formDataToSend.append(key, value as string | Blob);
      }
    });

    abortController.current = new AbortController();

    try {
      setFileUploadState((prev: any) => ({ ...prev, status: "uploading" }));

      await createTask({
        body: formDataToSend as any,
        abortController: abortController.current,
        onUploadProgress: (progressEvent: AxiosProgressEvent) => {
          const percentCompleted = progressEvent.total
            ? Math.round((progressEvent.loaded * 100) / progressEvent.total)
            : 0;

          setUploadProcessEvent({
            loaded: progressEvent.loaded,
            total: progressEvent.total,
            progress: percentCompleted,
          } as UploadProcessEvent);

          setFileUploadState((prev: any) => ({
            ...prev,
            progress: percentCompleted,
          }));
        },
      }).unwrap();

      setFileUploadState((prev: any) => ({ ...prev, status: "success" }));
      toast.success("Task successfully added");
      navigate("/results");
    } catch (error) {
      setFileUploadState((prev: any) => ({ ...prev, status: "error" }));
      toast.error("Error uploading video");
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            required
            label="File name"
            fullWidth
            value={formData.name}
            onChange={(e) => updateFormData("name", e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CategorySelect
            value={formData.categoryId}
            onChange={(value) => updateFormData("categoryId", value)}
            categories={allCategories?.categories}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <AutocompleteWithAdd
            onChange={(value) => updateFormData("tagName", value)}
            options={(allTags as any) || []}
            label="Tag"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            multiline
            rows={6}
            label="Description"
            fullWidth
            value={formData.description}
            onChange={(e) => updateFormData("description", e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <Divider>Advance Options</Divider>
        </Grid>
        <Grid item xs={12}>
          <AdvanceOptions
            blurFace={formData.blurFace}
            blurBack={formData.blurBack}
            multiTarget={formData.multiTarget}
            useCameraZ={formData.useCameraZ}
            onToggle={(key: keyof FormDataType, value) =>
              updateFormData(key, value)
            }
          />
        </Grid>
        <Grid item xs={12}>
          <Divider>Upload Video</Divider>
        </Grid>
        <Grid item xs={12}>
          <Dropzone
            onChange={handleFileChange}
            fileUploadStates={
              fileUploadState.file
                ? [
                    {
                      file: fileUploadState.file,
                      progress: fileUploadState.progress,
                      status: fileUploadState.status,
                      abortController: abortController.current!,
                    },
                  ]
                : []
            }
            onCancel={handleCancel}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            disabled={!isFormValid}
            onClick={handleSubmit}
            sx={{ width: "100%", maxWidth: "330px" }}
            variant="contained"
          >
            <Typography fontWeight="bold">Submit</Typography>
          </Button>
        </Grid>
      </Grid>

      <UploadProcessDialog
        handleClose={() => setUploadProcessEvent(undefined)}
        uploadProcessEvent={uploadProcessEvent}
        cancel={() => {
          abortController.current?.abort();
          setUploadProcessEvent(undefined);
        }}
      />
    </>
  );
};

export default SingleVideoUpload;
