import React from "react";
import {
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  Tooltip,
  Box,
  Stack,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

interface UseCameraZToggleProps {
  useCameraZ: boolean;
  onChange: (checked: boolean) => void;
}

const UseCameraZToggle: React.FC<UseCameraZToggleProps> = ({
  useCameraZ,
  onChange,
}) => {
  const handleChange = (
    _: React.MouseEvent<HTMLElement>,
    newValue: string | null,
  ) => {
    if (newValue !== null) {
      onChange(newValue === "camera");
    }
  };

  const tooltipContent = (
    <>
      <Typography variant="body2" paragraph>
        This toggle allows you to choose between two methods for calculating the
        trunk angle:
      </Typography>
      <Typography variant="body2" paragraph>
        <strong>Using camera Z-axis:</strong> Calculates the trunk angle
        relative to the camera's vertical axis. This method is generally more
        suitable for consistent camera setups and standardized recording
        environments.
      </Typography>
      <Typography variant="body2">
        <strong>Relative to hip:</strong> Calculates the trunk angle using the
        angle between the knee, hip, and shoulder. This method may provide more
        accurate results when camera angles vary or when the subject's
        orientation to the camera changes frequently.
      </Typography>
    </>
  );

  return (
    <Stack
      spacing={2}
      sx={{ height: "100%", border: "1px solid #ccc", borderRadius: 1, p: 1 }}
      justifyContent="space-evenly"
    >
      <Tooltip title={tooltipContent} placement="top-start" arrow>
        <Stack
          direction="row"
          sx={{
            cursor: "pointer",
          }}
        >
          <Typography variant="body2" fontWeight={600}>
            Trunk angle calculation method:
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <InfoOutlinedIcon
              sx={{
                ml: 1,
                color: "text.secondary",
                fontSize: 15,
              }}
            />
          </Box>
        </Stack>
      </Tooltip>
      <ToggleButtonGroup
        value={useCameraZ ? "camera" : "hip"}
        exclusive
        onChange={handleChange}
        aria-label="trunk angle calculation method"
        color="primary"
        fullWidth
        size="small"
      >
        <ToggleButton value="hip" aria-label="relative to hip">
          <Typography variant="body2">Relative to hip</Typography>
        </ToggleButton>
        <ToggleButton value="camera" aria-label="using camera Z-axis">
          <Typography variant="body2">camera Z-axis</Typography>
        </ToggleButton>
      </ToggleButtonGroup>
    </Stack>
  );
};

export default UseCameraZToggle;
