export enum stateType {
  DETECT_KEYPOINTS_PENDING = "DETECT_KEYPOINTS_PENDING",
  DETECTING_KEYPOINTS = "DETECTING_KEYPOINTS",

  CALCULATE_ANGLES_PENDING = "CALCULATE_ANGLES_PENDING",
  CALCULATING_ANGLES = "CALCULATING_ANGLES",

  GENERATE_VIDEO_PENDING = "GENERATE_VIDEO_PENDING",
  GENERATING_VIDEO = "GENERATING_VIDEO",

  READY = "READY",
  ERROR = "ERROR",
}

export type tableOrderType = "asc" | "desc";
