import { FC } from "react";
import { Grid, FormGroup, Tooltip, Chip } from "@mui/material";
import ToggleButton from "./ToggleButton";
import { FormDataType } from "./Single/types";
import UseCameraZSwitch from "./UseCameraZSwitch";

interface AdvanceOptionsProps {
  blurFace: boolean;
  blurBack: boolean;
  multiTarget: boolean;
  useCameraZ: boolean;
  onToggle: (key: keyof FormDataType, value: boolean) => void;
}

const AdvanceOptions: FC<AdvanceOptionsProps> = ({
  blurFace,
  blurBack,
  multiTarget,
  useCameraZ,
  onToggle,
}) => (
  <FormGroup>
    <Grid container spacing={1}>
      <Grid item xs={12} sm={6} lg={3} sx={{ position: "relative" }}>
        <ToggleButton
          text="Blur Faces"
          isSelected={blurFace}
          onToggle={() => onToggle("blurFace", !blurFace)}
          imageSrc="/new-task/blurface.png"
        />
      </Grid>
      <Grid item xs={12} sm={6} lg={3} sx={{ position: "relative" }}>
        <ToggleButton
          text="Blur Background"
          isSelected={blurBack}
          onToggle={() => onToggle("blurBack", !blurBack)}
          imageSrc="/new-task/blurback.png"
        />
      </Grid>
      <Grid item xs={12} sm={6} lg={3} sx={{ position: "relative" }}>
        <ToggleButton
          text="Multi-Subject Analysis"
          isSelected={multiTarget}
          onToggle={() => onToggle("multiTarget", !multiTarget)}
          imageSrc="/new-task/multisubject.png"
        />
        <Tooltip title="The multi-subject feature, still in development, is designed to analyze and conduct assessments on several individuals within a single uploaded video. This innovative functionality enables users to seamlessly toggle between the analysis results of each person included in the video.">
          <Chip
            sx={{ position: "absolute", top: 15, right: 5 }}
            label="Beta"
            size="small"
            color="warning"
          />
        </Tooltip>
      </Grid>
      <Grid item xs={12} sm={6} lg={3} sx={{ position: "relative" }}>
        <UseCameraZSwitch
          useCameraZ={useCameraZ}
          onChange={(value) => onToggle("useCameraZ", value)}
        />
      </Grid>
    </Grid>
  </FormGroup>
);

export default AdvanceOptions;
