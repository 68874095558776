import {
  ResultRo,
  SettingDto,
  TaskRo,
  ThresholdDto,
} from "src/app/services/generatedApi";
import {
  settingKeys,
  SettingsNamesEnum,
} from "src/components/molecules/category/types";

type ChartUtilsType = {
  task: TaskRo;
  selectedPart: keyof SettingDto;
};

type ResultStateType = {
  thresholds: ThresholdDto;
  number: number;
};

const filterValidNumbers = (arr: (number | undefined | null)[]): number[] =>
  arr.filter(
    (item): item is number =>
      item !== undefined && item !== null && !isNaN(item) && Boolean(item),
  );

const extractAmounts = ({ task, selectedPart }: ChartUtilsType): number[] => {
  const rawAmounts = task.result?.map((item) => item[selectedPart]) || [];
  return filterValidNumbers(rawAmounts);
};

const determineResultState = ({
  thresholds,
  number,
}: ResultStateType): "hazard" | "caution" | "safe" => {
  const { threshold1, threshold2, threshold3, threshold4 } = thresholds;

  if (
    (number >= threshold1 && number < threshold2) ||
    (number >= threshold3 && number < threshold4)
  ) {
    return "caution";
  } else if (number >= threshold2 && number < threshold3) {
    return "safe";
  } else {
    return "hazard";
  }
};

const calculatePercentage = (count: number, total: number): number =>
  Math.round((count / total) * 1000) / 10;

const calculateResultPercentage = ({ task, selectedPart }: ChartUtilsType) => {
  const amounts = extractAmounts({ task, selectedPart });
  const totalCount = amounts.length;

  const counts = amounts.reduce(
    (acc, amount) => {
      const state = determineResultState({
        thresholds: task.setting[selectedPart],
        number: amount,
      });
      acc[state]++;
      return acc;
    },
    { safe: 0, caution: 0, hazard: 0 },
  );

  return {
    name: SettingsNamesEnum[selectedPart],
    safe: calculatePercentage(counts.safe, totalCount),
    caution: calculatePercentage(counts.caution, totalCount),
    hazard: calculatePercentage(counts.hazard, totalCount),
  };
};

export const resultPercentageArray = (task: TaskRo) =>
  settingKeys.map((item) =>
    calculateResultPercentage({ task, selectedPart: item as keyof SettingDto }),
  );

export const twoDecimalPlaces = (number: number): number =>
  number === 0 ? 0 : Math.round(number * 100) / 100;

export const roundAngles = (results: ResultRo[]): ResultRo[] =>
  results.map((frameAngles) =>
    Object.fromEntries(
      settingKeys.map((key) => [
        key,
        frameAngles[key] !== undefined
          ? twoDecimalPlaces(frameAngles[key]!)
          : undefined,
      ]),
    ),
  );
