import { FC } from "react";
import Navbar from "src/components/organisms/layout/Navbar";
import Sidebar from "src/components/organisms/layout/Sidebar/Sidebar";
import { Box, Stack, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/system";
import ErrorBoundary from "../HOC/ErrorBoundaries";
import Footer from "../organisms/footer/Footer";

const Template: FC<{ children: React.ReactNode }> = ({ children }) => {
  const theme = useTheme();

  const isMd = useMediaQuery(theme.breakpoints.up("sm")),
    isLg = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <ErrorBoundary>
      <Box sx={{ width: "100%", height: "100vh" }}>
        <Navbar />
        <Sidebar />
        <Stack
          direction="row"
          alignItems="start"
          justifyContent="center"
          sx={{
            width: isLg ? "calc(100% - 260px)" : "100%",
            position: "relative",
            right: 0,
            left: isLg ? "260px" : 0,
            top: isMd ? "64px" : "56px",
            p: { xs: 0, md: 3, lg: 5 },
          }}
        >
          <Box
            sx={{
              width: "100%",
              maxWidth: "1200px",
            }}
          >
            {children}
            <Footer />
          </Box>
        </Stack>
      </Box>
    </ErrorBoundary>
  );
};

export default Template;
