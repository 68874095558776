import { AxiosRequestConfig } from "axios";
import {
  MediaControllerUploadVideoApiArg,
  MediaControllerUploadVideoApiResponse,
  TasksRo,
  enhancedApi,
} from "./generatedApi";

const addTagTypes = ["Task", "Category", "Tag", "User", "Assessment"] as const;

export const api = enhancedApi
  .injectEndpoints({
    endpoints: (build) => ({
      customMediaControllerUploadVideo: build.mutation<
        MediaControllerUploadVideoApiResponse,
        MediaControllerUploadVideoApiArg & {
          abortController: AbortController;
          onUploadProgress: AxiosRequestConfig["onUploadProgress"];
        }
      >({
        query: ({ body, abortController, onUploadProgress }) => ({
          url: `/api/v1/media`,
          method: "POST",
          body,
          abortController,
          onUploadProgress,
        }),
      }),
    }),
  })
  .enhanceEndpoints({
    addTagTypes,
    endpoints: {
      // ======================= TASKS ======================= //
      tasksControllerGetUserTasksWithPagination: {
        providesTags: (result?: TasksRo) =>
          result
            ? [
                ...result.tasks.map(({ id }) => ({
                  type: "Task" as const,
                  id,
                })),
                { type: "Task", id: "LIST" },
              ]
            : [{ type: "Task", id: "LIST" }],
      },
      tasksControllerGetAdminTasksWithPagination: {
        providesTags: (result?: TasksRo) =>
          result
            ? [
                ...result.tasks.map(({ id }) => ({
                  type: "Task" as const,
                  id,
                })),
                { type: "Task", id: "LIST" },
              ]
            : [{ type: "Task", id: "LIST" }],
      },
      tasksControllerUpdateUserTask: {
        invalidatesTags: (result, error, { taskUpdateDto }) => [
          { type: "Task", id: taskUpdateDto.id },
        ],
      },
      tasksControllerDeleteTasks: {
        invalidatesTags: (result, error, { deleteTaskDto }) => [
          ...deleteTaskDto.ids.map((id) => ({ type: "Task" as const, id })),
          { type: "Task", id: "LIST" },
        ],
      },
      tasksControllerGetTaskByIdForUser: {
        providesTags: (result, error, { id }) => [{ type: "Task", id }],
      },
      tasksControllerChangeTaskStateAsAdmin: {
        invalidatesTags: (result, error, { changeTaskStateDto }) => [
          { type: "Task", id: changeTaskStateDto.id },
          { type: "Task", id: "LIST" },
        ],
      },
      tasksControllerUpdateObservationAndRecommendation: {
        invalidatesTags: (
          result,
          error,
          { updateObservationRecommendationDto },
        ) => [
          { type: "Task", id: updateObservationRecommendationDto.id },
          { type: "Task", id: "LIST" },
        ],
      },
      // ======================= CATEGORY ======================= //
      categoryControllerCreateCategory: {
        invalidatesTags: [{ type: "Category", id: "LIST" }],
      },
      categoryControllerCreateSystemCategory: {
        invalidatesTags: [{ type: "Category", id: "LIST" }],
      },
      categoryControllerDeleteCategory: {
        invalidatesTags: (result, error, { idDto }) => [
          { type: "Category", id: idDto.id },
          { type: "Category", id: "LIST" },
        ],
      },
      categoryControllerUpdateCategory: {
        invalidatesTags: (result, error, { id }) => [
          { type: "Category", id },
          { type: "Category", id: "LIST" },
        ],
      },
      categoryControllerGetAllCategories: {
        providesTags: (result) =>
          result
            ? [
                ...result.categories.map(({ id }) => ({
                  type: "Category" as const,
                  id,
                })),
                { type: "Category", id: "LIST" },
              ]
            : [{ type: "Category", id: "LIST" }],
      },
      // ======================= TAGS ======================= //
      tasksControllerGetUserTagsForCurrentUser: {
        providesTags: (result) =>
          result
            ? [
                ...result.map(({ name }) => ({
                  type: "Tag" as const,
                  id: name,
                })),
                { type: "Tag", id: "LIST" },
              ]
            : [{ type: "Tag", id: "LIST" }],
      },
      // ======================= USERS ======================= //
      usersControllerCreateNewUser: {
        invalidatesTags: [{ type: "User", id: "LIST" }],
      },
      usersControllerUpdateExistingUser: {
        invalidatesTags: (result, error, { username }) => [
          { type: "User", id: username },
          { type: "User", id: "LIST" },
        ],
      },
      usersControllerSearchUsers: {
        providesTags: (users) =>
          users
            ? [
                ...users.map(({ username }) => ({
                  type: "User" as const,
                  id: username,
                })),
                { type: "User", id: "LIST" },
              ]
            : [{ type: "User", id: "LIST" }],
      },
      usersControllerUpdateUserProfile: {
        invalidatesTags: (result) =>
          result
            ? [
                { type: "User", id: result.username },
                { type: "User", id: "LIST" },
              ]
            : [{ type: "User", id: "LIST" }],
      },
      usersControllerSuspendUserByUsername: {
        invalidatesTags: (result, error, { username }) => [
          { type: "User", id: username },
          { type: "User", id: "LIST" },
        ],
      },
      usersControllerActivateUserByUsername: {
        invalidatesTags: (result, error, { username }) => [
          { type: "User", id: username },
          { type: "User", id: "LIST" },
        ],
      },
      usersControllerAssignUserRole: {
        invalidatesTags: (result, error, { userRoleDto }) => [
          { type: "User", id: userRoleDto.username },
          { type: "User", id: "LIST" },
        ],
      },
      usersUsageLimitControllerSetUserUsageLimit: {
        invalidatesTags: (result, error, { userLimitDto }) => [
          { type: "User", id: userLimitDto.username },
          { type: "User", id: "LIST" },
        ],
      },
      usersUsageLimitControllerGetUserUsage: {
        providesTags: (result) =>
          result ? [{ type: "User", id: result.creator }] : [],
      },
      usersUsageLimitControllerGetAllUsersUsage: {
        providesTags: [{ type: "User", id: "LIST" }],
      },
      // ======================= MEDIA ======================= //
      customMediaControllerUploadVideo: {
        invalidatesTags: [
          { type: "Task", id: "LIST" },
          { type: "Tag", id: "LIST" },
        ],
      },
      // ======================= ASSESSMENT ======================= //
      assessmentsControllerGetAssessments: {
        providesTags: (result) =>
          result
            ? [
                ...result.map(({ id }) => ({
                  type: "Assessment" as const,
                  id,
                })),
                { type: "Assessment", id: "LIST" },
              ]
            : [{ type: "Assessment", id: "LIST" }],
      },
      assessmentsControllerCreateAssessment: {
        invalidatesTags: [{ type: "Assessment", id: "LIST" }],
      },
      assessmentsControllerUpdateAssessment: {
        invalidatesTags: (result, error, { updateAssessmentDto }) => [
          { type: "Assessment", id: updateAssessmentDto.id },
          { type: "Assessment", id: "LIST" },
        ],
      },
      assessmentsControllerDeleteAssessment: {
        invalidatesTags: (result, error, { idDto }) => [
          { type: "Assessment", id: idDto.id },
          { type: "Assessment", id: "LIST" },
        ],
      },
      // ======================= AUTH ======================= //
      authControllerLogin: {
        invalidatesTags: [
          { type: "Task", id: "LIST" },
          { type: "Category", id: "LIST" },
          { type: "Tag", id: "LIST" },
          { type: "User", id: "LIST" },
          { type: "Assessment", id: "LIST" },
        ],
      },
    },
  });

export const {
  useCustomMediaControllerUploadVideoMutation,
  useLazyPoseControllerGetPersonFramesAnglesQuery,
} = api;
