type Range = {
  min?: number;
  max?: number;
};

export const rangeToString = ({ min, max }: Range): string => {
  if (min === undefined && max === undefined) {
    return "";
  }

  if (min === max) {
    return `${min}`;
  }

  const minStr = min !== undefined ? min.toString() : "";
  const maxStr = max !== undefined ? max.toString() : "";

  return `${minStr}${min !== undefined && max !== undefined ? "-" : ""}${maxStr}${max === undefined ? "+" : ""}`;
};
