import React, { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { toast } from "react-toastify";

import { useUsersPasswordControllerSendResetPasswordEmailMutation } from "src/app/services/generatedApi";
import Loading from "src/components/atoms/Loading";

type ForgotPasswordProps = {
  setForgot?: React.Dispatch<React.SetStateAction<boolean>>;
};

const ForgotPassword: FC<ForgotPasswordProps> = ({ setForgot }) => {
  const [email, setEmail] = useState("");
  const [sendMail, { isLoading }] =
    useUsersPasswordControllerSendResetPasswordEmailMutation();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("xs"));

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handleSendMail = async () => {
    if (!email) return;

    try {
      const baseUrl = window.location.origin;
      await sendMail({
        mailResetPasswordDto: {
          email,
          callbackUrl: `${baseUrl}/forgotPassword/` || "",
        },
      }).unwrap();

      toast.success(
        "If the entered email exists in our database, we will send further instructions to it.",
      );
      setForgot?.(false);
      navigate("/login");
    } catch (error) {
      toast.error("An error occurred while sending the reset email.");
    }
  };

  return (
    <>
      {isLoading && <Loading />}
      <Stack
        sx={{ width: "100vw", height: "100vh" }}
        justifyContent="center"
        alignItems="center"
      >
        <Paper
          elevation={isMd ? undefined : 0}
          sx={{
            width: { xs: "100%", sm: "400px" },
            height: { xs: "100%", sm: "auto" },
            p: { xs: 2, sm: 3, md: 4, lg: 5 },
            borderRadius: { xs: 0, sm: 1 },
          }}
          component={Stack}
          direction="column"
          spacing={4}
        >
          <Box>
            <Typography
              align="center"
              variant="h6"
              color="secondary"
              sx={{ pb: 1 }}
            >
              Password Reset
            </Typography>
            <Typography fontSize={13} color="secondary" align="justify">
              Please enter your email below and we will send you an email with a
              link to reset your password.
            </Typography>
          </Box>

          <TextField
            value={email}
            onChange={handleEmailChange}
            label="Email"
            type="email"
            placeholder="Please enter your email here"
            inputProps={{
              enterKeyHint: "send",
              autoComplete: "email",
              name: "email",
            }}
          />
          <Button
            variant="contained"
            onClick={handleSendMail}
            disabled={!email}
          >
            Send email
          </Button>
        </Paper>
        <Typography
          sx={{
            textDecoration: "none",
            cursor: "pointer",
            mt: 2,
          }}
          color="primary"
          fontSize={14}
          fontWeight="bold"
          onClick={() => setForgot?.(false)}
        >
          Back to sign in menu
        </Typography>
      </Stack>
    </>
  );
};

export default ForgotPassword;
