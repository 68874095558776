type TimeValue = string | number;

const padWithZero = (num: number): string => num.toString().padStart(2, "0");

export const convertToHMS = (value: TimeValue): string => {
  const totalSeconds = typeof value === "string" ? parseInt(value, 10) : value;

  if (isNaN(totalSeconds) || totalSeconds < 0) {
    throw new Error(
      "Invalid input. Please provide a non-negative number or a string that can be parsed to a number.",
    );
  }

  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;

  return `${padWithZero(hours)}:${padWithZero(minutes)}′:${padWithZero(seconds)}″`;
};
