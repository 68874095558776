import React, { FC } from "react";
import { Button, Checkbox, Stack, Typography } from "@mui/material";
import { green } from "@mui/material/colors";

interface ToggleButtonProps {
  text: string;
  isSelected: boolean;
  onToggle: () => void;
  imageSrc: string;
}

const ToggleButton: FC<ToggleButtonProps> = ({
  text,
  isSelected,
  onToggle,
  imageSrc,
}) => {
  return (
    <Button
      sx={{
        width: "100%",
        textTransform: "none",
        borderRadius: 1,
        border: "1px solid #ccc",
        background: "white",
        color: green[400],
        overflow: "hidden",
        minHeight: "120px",
        ...(isSelected && {
          border: `1px solid ${green[500]}`,
          color: green[600],
          background: green[50],
          "&:hover": {
            backgroundColor: green[50],
          },
        }),
        p: 1,
        position: "relative",
      }}
      variant={isSelected ? "contained" : "outlined"}
      onClick={onToggle}
    >
      <Stack spacing={1} alignItems="center">
        <img src={imageSrc} alt={text} style={{ height: "50px" }} />
        <Typography fontWeight={600}>{text}</Typography>
      </Stack>
      <Checkbox
        checked={isSelected}
        onChange={onToggle}
        sx={{
          color: green[400],
          "&.Mui-checked": {
            color: green[600],
          },
          position: "absolute",
          top: 0,
          left: 0,
          zIndex: 1,
          m: 0,
        }}
      />
    </Button>
  );
};

export default ToggleButton;
