import { useAppSelector } from "src/app/hooks";
import { getBaseUrl } from "src/app/services/baseQuery";

export const useCsvReportDownloader = (taskId: string) => {
  const token = useAppSelector((state) => state.auth?.token);

  const downloadCsvHandler = () => {
    const baseUrl = getBaseUrl();
    if (!token) return;

    const headers = new Headers();
    headers.append("Authorization", `Bearer ${token}`);

    fetch(`${baseUrl}/api/v1/personFrame/tasks/${taskId}/csv`, {
      headers,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.blob();
      })
      .then((blobby) => {
        const url = window.URL.createObjectURL(blobby);
        const anchor = document.createElement("a");
        anchor.href = url;
        anchor.download = "report.csv";
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("There was an error downloading the CSV report:", error);
      });
  };

  return downloadCsvHandler;
};
