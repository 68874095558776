import { FC, useContext, useEffect } from "react";
import { calculatePartScores } from "src/app/logic/pose/state";
import { TaskContext } from "src/context/TaskContext";

export const TaskStateListener: FC<{
  onChangePartsState: (data: { score: number; visibleCount: number }[]) => void;
  onChangeWholeState: (safe: number, hazard: number, caution: number) => void;
}> = ({ onChangePartsState, onChangeWholeState }) => {
  const { selectedAngles, setting } = useContext(TaskContext);

  useEffect(() => {
    const { partScores, totalSafe, totalCaution, totalHazard } =
      calculatePartScores(selectedAngles, setting);
    onChangePartsState(partScores);

    const sum = (totalSafe + totalCaution + totalHazard) / 100;
    onChangeWholeState(
      Math.round(totalSafe / sum),
      Math.round(totalCaution / sum),
      Math.round(totalHazard / sum),
    );
  }, [onChangePartsState, onChangeWholeState, selectedAngles, setting]);

  return <></>;
};
