import { useState } from "react";
import { FormDataType } from "./types";

export interface FileUploadState {
  file: File | null;
  progress: number;
  status: "uploading" | "success" | "error";
}

export const useFileUpload = (
  updateFormData: (key: keyof FormDataType, value: any) => void,
) => {
  const [fileUploadState, setFileUploadState] = useState<FileUploadState>({
    file: null,
    progress: 0,
    status: "uploading",
  });

  const handleFileChange = (files: File[]) => {
    const file = files[0];
    updateFormData("file", file);
    setFileUploadState({
      file,
      progress: 0,
      status: "uploading",
    });
  };

  const handleCancel = () => {
    setFileUploadState({
      file: null,
      progress: 0,
      status: "uploading",
    });
    updateFormData("file", null);
  };

  return {
    fileUploadState,
    setFileUploadState,
    handleFileChange,
    handleCancel,
  };
};
