import { useCallback } from "react";
import { twoDecimalPlaces } from "src/utils/chartsUtils";
import {
  extractRulaData,
  getRulaResult,
  getRulaScore,
} from "src/app/logic/assessment/rula/Rula";
import { getRulaQuestions } from "src/app/logic/assessment/rula/RulaQuestions";
import {
  extractRebaData,
  getRebaResult,
  getRebaScore,
} from "src/app/logic/assessment/reba/Reba";
import { getRebaQuestions } from "src/app/logic/assessment/reba/RebaQuestions";
import { ResultRo } from "src/app/services/generatedApi";

export interface ChartDataPoint {
  angle: number;
  seconds: number;
}

interface UseAssessmentLogicProps {
  selectedMethod?: "RULA" | "REBA" | "MMH";
  selectedAngles: ResultRo[];
  fps: number;
  isSited: boolean;
  frameIndex?: number;
  setAssessmentDataDto: (data: any) => void;
  setAssessmentPageIndex: (index: number) => void;
  setTargetId: (id: string | undefined) => void;
}

export const useAssessmentLogic = ({
  selectedMethod,
  selectedAngles,
  fps,
  isSited,
  frameIndex,
  setAssessmentDataDto,
  setAssessmentPageIndex,
  setTargetId,
}: UseAssessmentLogicProps) => {
  const getChartData = useCallback((): ChartDataPoint[] => {
    if (!selectedAngles) return [];

    return selectedAngles.map((frameAngles, index) => ({
      angle:
        selectedMethod === "RULA"
          ? getRulaScore(
              getRulaResult(
                extractRulaData(
                  getRulaQuestions(),
                  frameAngles,
                  undefined, // TODO: fix
                  isSited,
                ),
              ),
            )
          : getRebaScore(
              getRebaResult(
                extractRebaData(
                  getRebaQuestions(),
                  frameAngles,
                  undefined, // TODO: fix
                  isSited,
                ),
              ),
            ),
      seconds: twoDecimalPlaces(index / fps),
    }));
  }, [selectedMethod, selectedAngles, fps, isSited]);

  const handleUseSelectedFrame = useCallback(
    (pid: string | undefined) => {
      if (!frameIndex) return;

      const assessmentData =
        selectedMethod === "REBA"
          ? {
              reba: getRebaResult(
                extractRebaData(
                  getRebaQuestions(),
                  selectedAngles[frameIndex],
                  undefined, // TODO:fix
                  isSited,
                ),
              ),
            }
          : {
              rula: getRulaResult(
                extractRulaData(
                  getRulaQuestions(),
                  selectedAngles[frameIndex],
                  undefined, // TODO:fix
                  isSited,
                ),
              ),
            };

      setAssessmentDataDto(assessmentData);
      setAssessmentPageIndex(2);
      setTargetId(pid);
    },
    [
      frameIndex,
      selectedMethod,
      selectedAngles,
      isSited,
      setAssessmentDataDto,
      setAssessmentPageIndex,
      setTargetId,
    ],
  );

  return { getChartData, handleUseSelectedFrame };
};
