import { FC, useState } from "react";
import {
  Grid,
  Button,
  DialogActions,
  DialogContent,
  TextField,
  Dialog,
  DialogTitle,
} from "@mui/material";
import Loading from "src/components/atoms/Loading";
import {
  UserRo,
  useUsersControllerUpdateExistingUserMutation,
} from "src/app/services/generatedApi";
import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "src/app/hooks";
import { updateUserInfoAction } from "src/app/slice/authSlice";
import { shallowEqual } from "react-redux";

type UpdateUserDialogPropsType = {
  user: UserRo;
  open: boolean;
  handleClose: () => void;
};

const UpdateUserDialog: FC<UpdateUserDialogPropsType> = ({
  user,
  open,
  handleClose,
}) => {
  const [userInfo, setUserInfo] = useState(user);

  const dispatch = useAppDispatch();

  const { auth } = useAppSelector((state) => state);

  const [updateUser, { isLoading: updateUserLoading }] =
    useUsersControllerUpdateExistingUserMutation();

  const submitHandler = () => {
    if (shallowEqual(user, userInfo)) return;
    updateUser({
      username: userInfo.username,
      userUpdateDto: {
        firstName: userInfo.firstName,
        lastName: userInfo.lastName,
        email: userInfo.email,
      },
    })
      .unwrap()
      .then(() => {
        toast.success("User info successfully updated");
        if (
          user.username === auth?.user.username &&
          user.email === auth.user.email
        ) {
          dispatch(
            updateUserInfoAction({
              firstName: userInfo.firstName,
              lastName: userInfo.lastName,
              email: userInfo.email,
            }),
          );
        }
      });
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md">
      <DialogTitle>User Info</DialogTitle>
      <DialogContent>
        <form autoComplete="off">
          <Grid container spacing={3} sx={{ mt: 1 }}>
            <Grid item xs={12} md={6}>
              <TextField
                disabled={true}
                fullWidth
                label="Username"
                value={userInfo.username}
                onChange={({ target: { value } }) =>
                  setUserInfo((prevState) => ({
                    ...prevState,
                    username: value,
                  }))
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="First Name"
                value={userInfo.firstName}
                onChange={({ target: { value } }) =>
                  setUserInfo((prevState) => ({
                    ...prevState,
                    firstName: value,
                  }))
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Last Name"
                value={userInfo.lastName}
                onChange={({ target: { value } }) =>
                  setUserInfo((prevState) => ({
                    ...prevState,
                    lastName: value,
                  }))
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Email"
                type="email"
                value={userInfo.email}
                onChange={({ target: { value } }) =>
                  setUserInfo((prevState) => ({ ...prevState, email: value }))
                }
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions
        sx={{ pr: { xs: 1, sm: 2, md: 4 }, pb: { xs: 1, sm: 2, md: 3 } }}
      >
        <Button sx={{ width: "100%", maxWidth: "150px" }} onClick={handleClose}>
          Cancel
        </Button>
        <Button
          onClick={submitHandler}
          disabled={shallowEqual(user, userInfo)}
          sx={{ width: "100%", maxWidth: "150px" }}
          variant="contained"
          type="submit"
        >
          Update
        </Button>
      </DialogActions>
      {updateUserLoading && <Loading />}
    </Dialog>
  );
};

export default UpdateUserDialog;
