import { useState, useEffect, useCallback } from "react";
import { FormDataType } from "./types";
import { CategoriesRo } from "src/app/services/generatedApi";

export const useFormData = (allCategories?: CategoriesRo) => {
  const [formData, setFormData] = useState<FormDataType>({
    name: "",
    file: null,
    categoryId: "",
    tagName: null,
    description: "",
    blurFace: false,
    blurBack: false,
    multiTarget: false,
    useCameraZ: true, // New property, default set to true
  });

  useEffect(() => {
    if (allCategories) {
      const defaultCategory = allCategories.categories.find(
        (cat) => cat.name === "Default" && cat.isSystemCategory,
      );
      if (defaultCategory) {
        setFormData((prev) => ({ ...prev, categoryId: defaultCategory.id }));
      }
    }
  }, [allCategories]);

  const updateFormData = useCallback(
    <K extends keyof FormDataType>(key: K, value: FormDataType[K]) => {
      setFormData((prev) => ({ ...prev, [key]: value }));
    },
    [],
  );

  const resetForm = useCallback(() => {
    setFormData({
      name: "",
      file: null,
      categoryId: "",
      tagName: null,
      description: "",
      blurFace: false,
      blurBack: false,
      multiTarget: false,
      useCameraZ: true,
    });
  }, []);

  const isFormValid = Boolean(
    formData.name && formData.file && formData.categoryId,
  );

  return { formData, updateFormData, resetForm, isFormValid };
};
