import { UserRo, UserUsageAndLimitRo } from "src/app/services/generatedApi";
import { UserWithUsages } from "src/types/userTypes";
import { liveSearch } from "./liveSearchUtils";

type FilterByRoleParams = {
  users: UserWithUsages[];
  selectedRole: string;
};

export const filterByRole = ({
  users,
  selectedRole,
}: FilterByRoleParams): UserWithUsages[] =>
  users.filter(({ roleNames }) => roleNames.includes(selectedRole));

type UsersFilterParams = {
  users?: UserWithUsages[];
  selectedRole: string;
  inputValue: string;
  searchItem: keyof UserRo;
};

export const usersFilter = ({
  users = [],
  selectedRole,
  inputValue,
  searchItem,
}: UsersFilterParams): UserWithUsages[] => {
  let filteredUsers = [...users];

  if (inputValue) {
    filteredUsers = liveSearch({
      inputValue,
      originalArray: filteredUsers,
      searchItem,
    });
  }

  if (selectedRole !== "All") {
    filteredUsers = filterByRole({ users: filteredUsers, selectedRole });
  }

  return filteredUsers;
};

export type AddUsersUsageParams = {
  users: UserRo[] | undefined;
  usersUsageAndLimit: UserUsageAndLimitRo[] | undefined;
};

export const addUsersUsage = ({
  users,
  usersUsageAndLimit,
}: AddUsersUsageParams): UserWithUsages[] => {
  if (!users || !usersUsageAndLimit) return [];

  return users.map((user) => {
    const userUsageAndLimit = usersUsageAndLimit.find(
      ({ creator }) => creator === user.username,
    );

    return {
      ...user,
      usage: userUsageAndLimit?.usage,
    };
  });
};
