import { FC } from "react";
import {
  useTasksControllerGetAdminTasksWithPaginationQuery,
  useTasksControllerChangeTaskStateAsAdminMutation,
} from "src/app/services/generatedApi";
import { toast } from "react-toastify";
import { allTasksHeadCells } from "src/components/organisms/tasks/constants";
import BaseTasksComponent from "src/components/organisms/tasks/BaseTasksComponent";

const AllTasks: FC = () => {
  const [changeTaskState] = useTasksControllerChangeTaskStateAsAdminMutation();

  const refresh = (taskId: string) => {
    changeTaskState({
      changeTaskStateDto: { id: taskId, state: "DETECT_KEYPOINTS_PENDING" },
    })
      .unwrap()
      .then(() => toast.success("Send reprocessing request."));
  };

  return (
    <BaseTasksComponent
      title="All videos"
      headCells={allTasksHeadCells}
      fetchTasks={useTasksControllerGetAdminTasksWithPaginationQuery}
      isAllTasks={true}
      onRefresh={refresh}
    />
  );
};

export default AllTasks;
