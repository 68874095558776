import { Box, Grid, Paper, Stack, Typography } from "@mui/material";
import moment from "moment";
import { FC, useState } from "react";
import { TaskRo } from "src/app/services/generatedApi";
import { EventEmitterProvider } from "src/components/hook/useEventEmitter";
import TaskContextProvider from "src/context/TaskContext";
import { TaskStateListener } from "./TaskStateListener";
import { VideoPlayer } from "src/components/molecules/result/VideoPlayer/VideoPlayer";
import AspectRatioBox from "src/components/atoms/AspectRatioBox";
import { HumanBodySVG } from "src/components/molecules/result/humanBodySVG/HumanBodySVG";
import { ShowAngleThreshold } from "src/components/molecules/result/ShowAngleThresholds";
import { BodyPartsSection } from "src/components/molecules/result/BodyPartSection";
import { OverallChart } from "src/components/molecules/result/charts/OverallChart";
import { AllBodyPartsSection } from "src/components/molecules/result/AllBodyPartsSection";
import { TaskPieChart } from "./charts/TaskPieChart";

export const TaskView: FC<{
  task: TaskRo;
  isRight?: boolean;
  onChangePartsState: (data: { score: number; visibleCount: number }[]) => void;
}> = ({ task, isRight = false, onChangePartsState }) => {
  const [wholeTaskState, setWholeTaskState] = useState([
    { name: "safe", value: 0 },
    { name: "caution", value: 0 },
    { name: "hazard", value: 0 },
  ]);
  return (
    <>
      <Box sx={{ mb: 2 }}>
        <Typography
          variant="h6"
          fontWeight={600}
          textAlign="center"
          sx={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {task.name}
        </Typography>
        <Typography
          variant="caption"
          textAlign="center"
          sx={{ width: "100%", display: "block" }}
        >
          {moment(task.createdAt).format("YYYY/MM/DD")}
        </Typography>
      </Box>
      <TaskContextProvider task={task}>
        <EventEmitterProvider>
          <TaskStateListener
            onChangePartsState={onChangePartsState}
            onChangeWholeState={(safe, caution, hazard) =>
              setWholeTaskState([
                { name: "safe", value: safe },
                { name: "caution", value: caution },
                { name: "hazard", value: hazard },
              ])
            }
          />
          <Grid container alignItems="center" spacing={2}>
            <Grid item xs={12}>
              <Paper sx={{ width: "100%", p: 1 }}>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                  spacing={2}
                >
                  <Grid item xs={12} md={8} order={isRight ? 1 : 0}>
                    <Stack sx={{ height: 300 }} alignItems="center">
                      <VideoPlayer
                        fileName={task.outFileName}
                        havePose={task.multiTarget}
                        controls
                        mouseListening
                        maxHeight={300}
                      />
                    </Stack>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={4}
                    sx={{
                      display: { xs: "none", md: "flex" },
                      "& .recharts-pie-label-text": {
                        fill: "black",
                        fontWeight: 600,
                      },
                      "& .recharts-pie-label-line": { stroke: "black" },
                    }}
                    order={isRight ? 0 : 1}
                  >
                    <Box
                      sx={{
                        minWidth: 100,
                        minHeight: 100,
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      <AspectRatioBox>
                        <TaskPieChart wholeTaskState={wholeTaskState} />
                      </AspectRatioBox>
                    </Box>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>

            <Grid item xs={12} order={2}>
              <Box sx={{ px: 1, py: 6 }}>
                <Stack
                  direction="row"
                  justifyContent={{
                    xs: "center",
                    lg: isRight ? "flex-start" : "flex-end",
                  }}
                >
                  <Stack justifyContent="center">
                    <HumanBodySVG maxHeight={340} defaultAverage />
                    <ShowAngleThreshold setting={task.setting} />
                  </Stack>
                </Stack>
              </Box>
            </Grid>
            <Grid item xs={12} order={2}>
              <BodyPartsSection />
            </Grid>
            <Grid item xs={12} order={2}>
              <OverallChart />
            </Grid>
            <Grid item xs={12} order={2}>
              <AllBodyPartsSection />
            </Grid>
          </Grid>
        </EventEmitterProvider>
      </TaskContextProvider>
    </>
  );
};
