import { FC, MouseEvent, useState } from "react";
import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import * as yup from "yup";
import { passwordValidationRegex } from "src/utils/regexUtils";
import { Form, Formik } from "formik";
import { formikOnSubmitType } from "src/types/formTypes";
import {
  useUsersPasswordControllerChangeUserPasswordMutation,
  useUsersPasswordControllerResetPasswordMutation,
} from "src/app/services/generatedApi";
import Loading from "src/components/atoms/Loading";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const passValidationHandler = (value: string) =>
  !value ? false : passwordValidationRegex.test(value);

type ChangePasswordPropsType = {
  resetCode?: number;
  email?: string;
};

const ChangePassword: FC<ChangePasswordPropsType> = ({ resetCode, email }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const navigate = useNavigate();
  const [changePassword, { isLoading: changeLoading }] =
    useUsersPasswordControllerChangeUserPasswordMutation();

  const [resetPassword, { isLoading: resetLoading }] =
    useUsersPasswordControllerResetPasswordMutation();

  const showPasswordHandler = () => setShowPassword((prevState) => !prevState);
  const showPasswordConfirmHandler = () =>
    setShowPasswordConfirm((prevState) => !prevState);
  const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) =>
    event.preventDefault();

  const formValidationSchema = yup.object({
    newPassword: yup
      .string()
      .test(
        "passwordValidation",
        "The password must be between 8 and 20 characters long and contain at least one uppercase, one lowercase, and one number.",
        (value) => passValidationHandler(value as string),
      )
      .required("This section is required"),
    newPasswordConfirm: yup
      .string()
      .oneOf(
        [yup.ref("newPassword"), undefined],
        "Repeating the new password is incorrect",
      )
      .required("This section is required"),
  });

  const formInitializer = {
    newPassword: "",
    newPasswordConfirm: "",
  };

  const formSubmitHandler: formikOnSubmitType<typeof formInitializer> = (
    { newPassword },
    { setSubmitting, resetForm },
  ) => {
    if (resetCode && email) {
      resetPassword({
        resetCode,
        email,
        changePasswordDto: { password: newPassword },
      })
        .unwrap()
        .then(() => {
          toast.success("Your password successfully updated");
          navigate("/login");
        });
    } else {
      changePassword({ changePasswordDto: { password: newPassword } })
        .unwrap()
        .then(() => {
          toast.success("Your password successfully updated");
        });
    }
    setSubmitting(false);
    resetForm();
  };

  return (
    <>
      {(changeLoading || resetLoading) && <Loading />}
      <Stack spacing={3}>
        <Typography variant="h6">Change Password</Typography>
        <Formik
          initialValues={formInitializer}
          validationSchema={formValidationSchema}
          onSubmit={formSubmitHandler}
        >
          {({ errors, touched, getFieldProps, values }) => (
            <Form autoComplete="off">
              <Grid container spacing={6}>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="New password *"
                    fullWidth
                    type={showPassword ? "text" : "password"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={showPasswordHandler}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    error={Boolean(
                      errors["newPassword"] && touched["newPassword"],
                    )}
                    helperText={errors["newPassword"]}
                    {...getFieldProps("newPassword")}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Confirm new password *"
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={showPasswordConfirmHandler}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPasswordConfirm ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    error={Boolean(
                      errors["newPasswordConfirm"] &&
                        touched["newPasswordConfirm"],
                    )}
                    type={showPasswordConfirm ? "text" : "password"}
                    helperText={errors["newPasswordConfirm"]}
                    {...getFieldProps("newPasswordConfirm")}
                  />
                </Grid>
              </Grid>
              <Stack justifyContent="center" alignItems="center">
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  sx={{
                    width: "150px",
                    mt: 5,
                    fontSize: 17,
                  }}
                  disabled={
                    !values["newPassword"] || !values["newPasswordConfirm"]
                  }
                >
                  Submit
                </Button>
              </Stack>
            </Form>
          )}
        </Formik>
      </Stack>
    </>
  );
};

export default ChangePassword;
