export const getVideoDuration = (file: File): Promise<number> => {
  return new Promise((resolve, reject) => {
    let errorCount = 0;
    const URL = window.URL || window.webkitURL;

    const video = document.createElement("video");
    video.preload = "metadata";

    video.onloadedmetadata = function () {
      URL.revokeObjectURL(video.src);
      resolve(video.duration);
    };

    video.onerror = () => {
      errorCount++;
      if (errorCount === 2) reject(new Error("Error loading video metadata"));
    };

    video.src = URL.createObjectURL(file);

    const reader = new FileReader();
    reader.onload = () => {
      const media = new Audio(reader.result as string | undefined);
      media.onloadedmetadata = () => {
        resolve(media.duration);
      };
    };
    reader.onerror = () => {
      errorCount++;
      if (errorCount === 2) reject(new Error("Error reading file"));
    };

    reader.readAsDataURL(file);
  });
};

export const removeFileExtension = (fileName: string) => {
  const lastDotIndex = fileName.lastIndexOf(".");
  return lastDotIndex === -1 ? fileName : fileName.substring(0, lastDotIndex);
};
