import { FC, ReactNode } from "react";
import { Paper, Stack, Typography, Link } from "@mui/material";
import { Warning as WarningIcon } from "@mui/icons-material";
import Template from "src/components/templates/Template";

const CONTACT_EMAIL = "info@posechecker.com";

const WarningMessage: FC<{
  children: ReactNode;
}> = ({ children }) => (
  <Typography variant="body1" component="p">
    {children}
  </Typography>
);

export const AddVideoLimitReached: FC = () => {
  return (
    <Template>
      <Paper sx={{ p: 2 }}>
        <Stack direction="row" alignItems="center" spacing={3}>
          <WarningIcon fontSize="large" color="error" />
          <div>
            <WarningMessage>
              You've reached your subscription limit.
            </WarningMessage>
            <WarningMessage>
              Please contact us at{" "}
              <Link href={`mailto:${CONTACT_EMAIL}`}>{CONTACT_EMAIL}</Link> to
              renew your subscription.
            </WarningMessage>
          </div>
        </Stack>
      </Paper>
    </Template>
  );
};
