import {
  Grid,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { BodyPartsChart } from "./charts/BodyPartsChart";
import {
  settingKeys,
  SettingsNamesEnum,
} from "src/components/molecules/category/types";

export const AllBodyPartsSection = () => {
  const theme = useTheme(),
    isMd = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <Paper
      elevation={isMd ? undefined : 0}
      sx={{
        width: "100%",
        p: { xs: 2, sm: 3, md: 4, lg: 5 },
        borderRadius: { xs: 0, md: 1 },
      }}
    >
      <Typography variant="h6" align="center" color="secondary" sx={{ pb: 3 }}>
        Compare body parts chart
      </Typography>
      <Grid
        container
        rowSpacing={4}
        columnSpacing={1}
        id="compare-body-parts-chart"
      >
        {settingKeys.map((part, index) => (
          <Grid key={index} item xs={12} md={6} sx={{ height: "160px" }}>
            <Typography fontSize={14} fontWeight="bold" align="center">
              {SettingsNamesEnum[part]}
            </Typography>
            <BodyPartsChart selectedPart={part} needLegend={false} />
          </Grid>
        ))}
      </Grid>
    </Paper>
  );
};
