import { AllInclusive as AllInclusiveIcon } from "@mui/icons-material";
import { Paper, Grid, Typography, Skeleton, Stack, Box } from "@mui/material";
import { FC } from "react";
import { useUsersUsageLimitControllerGetUserUsageQuery } from "src/app/services/generatedApi";
import { convertToHMS } from "src/utils/timeUtils";

export const ProfileOverView: FC = () => {
  const { data, isLoading } = useUsersUsageLimitControllerGetUserUsageQuery(
    null as any,
    {
      refetchOnMountOrArgChange: 10,
    },
  );

  const remainingDayes = data?.limit.date
    ? Math.max(
        0,
        Math.ceil(
          (new Date(data?.limit.date).getTime() - Date.now()) /
            (1000 * 3600 * 24),
        ),
      )
    : undefined;

  const usage = [
    {
      label: "Video Count",
      value: data?.usage.count || 0,
      subValue: (
        <Stack alignItems="center" direction="row" spacing={0.5}>
          <Typography sx={{ color: "#555", fontSize: "0.8rem" }}>/</Typography>
          <Typography sx={{ color: "#555", fontSize: "0.8rem" }}>
            {data?.limit.count === undefined ? (
              <AllInclusiveIcon fontSize="small" />
            ) : (
              data.limit.count
            )}
          </Typography>
        </Stack>
      ),
    },
    {
      label: "Total Duration",
      value: convertToHMS(Math.round(data?.usage.duration || 0)),
    },
    {
      label: "Remaining Days",
      value:
        remainingDayes === undefined ? (
          <AllInclusiveIcon fontSize="large" />
        ) : (
          remainingDayes
        ),
      subValue: (
        <Typography sx={{ color: "#555", fontSize: "0.8rem" }}>
          {remainingDayes === 1 ? "Day" : "Days"}
        </Typography>
      ),
    },
  ];

  return (
    <Stack spacing={3}>
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography variant="h6">User Profile</Typography>
        <Typography>
          {data?.userCreateDate &&
            `(activated at ${new Date(data?.userCreateDate).toLocaleDateString(
              "en-US",
            )})`}
        </Typography>
      </Stack>
      <Box>
        <Grid
          container
          spacing={2}
          justifyContent="space-around"
          alignItems="center"
        >
          {usage.map((usage) => (
            <Grid item md={4} sm={6} xs={12}>
              <Paper sx={{ height: 150, overflow: "hidden" }}>
                {isLoading ? (
                  <Skeleton variant="rectangular" height="100%"></Skeleton>
                ) : (
                  <Stack sx={{ p: 2, height: "100%" }} justifyContent="stretch">
                    <Typography sx={{ fontWeight: 600, color: "#494949" }}>
                      {usage.label}
                    </Typography>
                    <Stack
                      alignItems="center"
                      justifyContent="center"
                      sx={{ flexGrow: 1 }}
                    >
                      <Box sx={{ position: "relative" }}>
                        <Typography
                          variant="h3"
                          sx={{ fontWeight: 700, color: "#1a1a1a" }}
                        >
                          {usage.value}
                        </Typography>
                        {usage.subValue && (
                          <Box
                            sx={{
                              position: "absolute",
                              bottom: -10,
                              left: "95%",
                              fontSize: 15,
                              whiteSpace: "nowrap",
                            }}
                          >
                            {usage.subValue}
                          </Box>
                        )}
                      </Box>
                    </Stack>
                  </Stack>
                )}
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Stack>
  );
};
