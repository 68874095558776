import {
  FC,
  createContext,
  useState,
  ReactNode,
  useMemo,
  useCallback,
} from "react";
import { getState } from "src/app/logic/pose/state";
import { BodyPartState } from "src/app/logic/pose/types";
import {
  AnglesRo,
  SettingDto,
  TaskRo,
  usePoseControllerGetPersonFramesAnglesQuery,
} from "src/app/services/generatedApi";
import { categoryDefaultSettings } from "src/components/molecules/category/constants";

type TaskContextType = {
  taskId: string;
  setting: SettingDto;
  havePose: boolean;
  duration: number;
  fps: number;
  selectedAngles: AnglesRo[];
  setDuration: (seconds: number) => void;
  isSited: boolean;
  openNotes: boolean;
  selectedPersonId?: string;
  onChangeSelectedPersonId: (personId?: string) => void;
  changeSelectedPersonIdIfNeeded: (
    personId: string,
    frameIndex: number,
  ) => void;
  setOpenNotes: (openNotes: boolean) => void;
};

export const TaskContext = createContext<TaskContextType>({
  taskId: "",
  setting: categoryDefaultSettings,
  havePose: false,
  duration: 0,
  fps: 30,
  selectedAngles: [],
  setDuration: () => {},
  isSited: false,
  openNotes: false,
  onChangeSelectedPersonId: () => {},
  changeSelectedPersonIdIfNeeded: () => {},
  setOpenNotes: () => {},
});

type TaskContextProviderPropsType = {
  task: TaskRo;
  children?: ReactNode;
};

const TaskContextProvider: FC<TaskContextProviderPropsType> = ({
  task,
  children,
}) => {
  const [duration, setDuration] = useState(0);
  const [selectedPersonId, setSelectedPersonId] = useState<string>();
  const [openNotes, setOpenNotes] = useState(false);

  const { data: selectedAngles = [] } =
    usePoseControllerGetPersonFramesAnglesQuery({
      taskId: task.id,
      personId: selectedPersonId! || "best",
    });

  const framesCount = useMemo(
    () => task.videoFrameLength || 0,
    [task.videoFrameLength],
  );

  const fps = useMemo(
    () => (duration === 0 ? 30 : framesCount / duration),
    [framesCount, duration],
  );

  const isSited = useMemo<boolean>(() => {
    return getState(task.setting, "back", 90) !== BodyPartState.HAZARD;
  }, [task.setting]);

  const onChangeSelectedPersonId = useCallback(
    (personId?: string) =>
      personId === undefined
        ? setSelectedPersonId(undefined)
        : setSelectedPersonId(personId),
    [setSelectedPersonId],
  );

  const changeSelectedPersonIdIfNeeded = useCallback(
    (personId: string, frameIndex: number) =>
      setSelectedPersonId((prev) => {
        if (prev === personId || typeof prev === "string") {
          return personId;
        }
        return prev;
      }),
    [],
  );

  return (
    <TaskContext.Provider
      value={{
        taskId: task.id,
        setting: task.setting,
        havePose: task.havePose,
        duration,
        fps,
        selectedAngles,
        setDuration,
        openNotes,
        isSited,
        selectedPersonId,
        onChangeSelectedPersonId,
        changeSelectedPersonIdIfNeeded,
        setOpenNotes,
      }}
    >
      {children}
    </TaskContext.Provider>
  );
};

export default TaskContextProvider;
