import { Button } from "@mui/material";
import { FC } from "react";
import { useNavigate } from "react-router";
import { useAppSelector } from "src/app/hooks";
import { Cached as CachedIcon } from "@mui/icons-material";
import { useTasksControllerChangeTaskStateAsAdminMutation } from "src/app/services/generatedApi";

type ReprocessButtonProps = {
  taskId: string;
};

export const ReprocessButton: FC<ReprocessButtonProps> = ({ taskId }) => {
  const [changeTaskState] = useTasksControllerChangeTaskStateAsAdminMutation();
  const navigate = useNavigate();
  const isAdmin = useAppSelector((state) => state.auth?.isAdmin);

  const reProcess = () => {
    if (!isAdmin) {
      return;
    }

    changeTaskState({
      changeTaskStateDto: { state: "DETECT_KEYPOINTS_PENDING", id: taskId },
    })
      .unwrap()
      .then(() => {
        navigate("/allTasks");
      })
      .catch((error) => {
        console.error("Failed to change task state", error);
      });
  };

  return (
    <Button variant="outlined" startIcon={<CachedIcon />} onClick={reProcess}>
      Reprocess
    </Button>
  );
};
