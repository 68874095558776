import { FC } from "react";
import { useSearchParams } from "react-router-dom";
import { useUsersPasswordControllerVerifyResetPasswordCredentialsQuery } from "src/app/services/generatedApi";
import Loading from "src/components/atoms/Loading";
import ChangePassword from "src/components/organisms/profile/ChangePassword";
import { Navigate } from "react-router";
import { Paper, Stack, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/system";

const ResetPassword: FC = () => {
  let [searchParams] = useSearchParams();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("sm"));

  const { isLoading, isSuccess, isError } =
    useUsersPasswordControllerVerifyResetPasswordCredentialsQuery({
      resetCode: Number(searchParams.get("resetCode")),
      email: searchParams.get("email") as string,
    });

  if (!searchParams.get("resetCode") || !searchParams.get("email") || isError) {
    return <Navigate to="/login" />;
  } else {
    return (
      <Stack
        justifyContent="center"
        alignItems="center"
        sx={{ width: "100vw", height: "100vh", p: 0, m: 0 }}
      >
        {isLoading && <Loading />}
        {!isLoading && isSuccess && (
          <Paper
            elevation={isMd ? undefined : 0}
            sx={{
              width: "100%",
              p: { xs: 2, sm: 3, md: 4, lg: 5 },
              borderRadius: { xs: 0, md: 1 },
              maxWidth: "900px",
              height: { xs: "100%", md: "auto" },
            }}
          >
            <Typography
              sx={{ mb: 6 }}
              variant="h6"
              align="center"
              color="primary"
            >
              Please enter your new password
            </Typography>
            <ChangePassword
              resetCode={Number(searchParams.get("resetCode"))}
              email={searchParams.get("email") as string}
            />
          </Paper>
        )}
      </Stack>
    );
  }
};

export default ResetPassword;
