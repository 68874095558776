import React, { FC, useState, useEffect } from "react";
import {
  Stack,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Typography,
  Button,
} from "@mui/material";
import { CategoriesRo, TagRo } from "src/app/services/generatedApi";
import { nameIdType } from "src/components/atoms/AutocompleteWithAdd";
import TaskInfoStep, { TaskInfo } from "./TaskInfoStep";
import { UploadStep } from "./UploadStep";
import AdvanceStep from "./AdvanceStep";

type MultipleVideoUploadProps = {
  allCategories?: CategoriesRo;
  allTags?: TagRo[];
};

const MultipleVideoUpload: FC<MultipleVideoUploadProps> = ({
  allCategories,
  allTags,
}) => {
  const [taskInfo, setTaskInfo] = useState<TaskInfo>({
    name: "",
    categoryId: "",
    tagName: null as string | nameIdType | null,
    description: "",
  });

  const [advancedOptions, setAdvancedOptions] = useState({
    blurFace: false,
    blurBack: false,
    multiTarget: false,
    useCameraZ: true,
  });

  const [activeStep, setActiveStep] = useState(0);

  const steps = [
    {
      label: "Task Info",
      description: "Enter file details, select category, and tags.",
      component: (
        <TaskInfoStep
          {...taskInfo}
          setTaskInfo={setTaskInfo}
          allCategories={allCategories}
          allTags={allTags}
        />
      ),
    },
    {
      label: "Advanced Options",
      description:
        "Set advanced options like blurring and multi-target analysis.",
      component: (
        <AdvanceStep
          values={advancedOptions}
          onValuesChange={setAdvancedOptions}
        />
      ),
    },
    {
      label: "Upload",
      description: "Upload your videos.",
      component: (
        <UploadStep
          initialValues={{ ...taskInfo, ...advancedOptions }}
          onCompleted={() => setActiveStep(steps.length)}
          handleBack={() => setActiveStep((prev) => prev - 1)}
        />
      ),
    },
  ];

  useEffect(() => {
    if (allCategories) {
      const defaultCategory = allCategories.categories.find(
        (cat) => cat.name === "Default" && cat.isSystemCategory,
      );
      if (defaultCategory) {
        setTaskInfo((prev) => ({ ...prev, category: defaultCategory.id }));
      }
    }
  }, [allCategories]);

  const handleNext = () => {
    if (activeStep === 0 && (!taskInfo.name || !taskInfo.categoryId)) return;
    setActiveStep((prev) => prev + 1);
  };

  const handleBack = () => setActiveStep((prev) => prev - 1);

  return (
    <Stepper activeStep={activeStep} orientation="vertical">
      {steps.map((step, index) => (
        <Step key={step.label}>
          <StepLabel>{step.label}</StepLabel>
          <StepContent>
            <Typography mb={2}>{step.description}</Typography>
            {step.component}
            {index < steps.length - 1 && (
              <Stack
                direction="row"
                alignItems="center"
                sx={{ mt: 2 }}
                spacing={2}
              >
                <Button
                  variant="contained"
                  onClick={handleNext}
                  disabled={
                    index === 0 && (!taskInfo.name || !taskInfo.categoryId)
                  }
                >
                  Continue
                </Button>
                <Button disabled={index === 0} onClick={handleBack}>
                  Back
                </Button>
              </Stack>
            )}
          </StepContent>
        </Step>
      ))}
    </Stepper>
  );
};

export default MultipleVideoUpload;
