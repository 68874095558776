import { FC } from "react";
import { Grid, TextField } from "@mui/material";
import AutocompleteWithAdd, {
  nameIdType,
} from "src/components/atoms/AutocompleteWithAdd";
import CategorySelect from "../CategorySelect";

type TaskInfoStepProps = {
  name: string;
  categoryId: string;
  tagName: string | nameIdType | null;
  description: string;
  setTaskInfo: React.Dispatch<React.SetStateAction<TaskInfo>>;
  allCategories: any;
  allTags: any;
};

export type TaskInfo = {
  name: string;
  categoryId: string;
  tagName: string | nameIdType | null;
  description: string;
};

const TaskInfoStep: FC<TaskInfoStepProps> = ({
  name,
  categoryId,
  description,
  setTaskInfo,
  allCategories,
  allTags,
}) => {
  const handleChange = <K extends keyof TaskInfo>(
    field: K,
    value: TaskInfo[K],
  ) => {
    setTaskInfo((prev) => ({ ...prev, [field]: value }));
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          required
          label="File name"
          fullWidth
          value={name}
          onChange={(e) => handleChange("name", e.target.value)}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <CategorySelect
          value={categoryId}
          onChange={(value) => handleChange("categoryId", value)}
          categories={allCategories?.categories}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <AutocompleteWithAdd
          onChange={(value) => handleChange("tagName", value)}
          options={(allTags as any) || []}
          label="Tag"
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          multiline
          rows={6}
          label="Description"
          fullWidth
          value={description}
          onChange={(e) => handleChange("description", e.target.value)}
        />
      </Grid>
    </Grid>
  );
};

export default TaskInfoStep;
