const FILE_SIZE_UNITS = ["B", "kB", "MB", "GB", "TB"] as const;

export const humanFileSize = (sizeInBytes?: number): string => {
  if (sizeInBytes === undefined || isNaN(sizeInBytes) || sizeInBytes < 0) {
    return "0 B";
  }

  if (sizeInBytes === 0) {
    return "0 B";
  }

  const magnitude = Math.min(
    Math.floor(Math.log(sizeInBytes) / Math.log(1000)),
    FILE_SIZE_UNITS.length - 1,
  );
  const scaledSize = sizeInBytes / Math.pow(1000, magnitude);
  const formattedSize =
    magnitude > 0 ? scaledSize.toFixed(2) : scaledSize.toString();

  return `${formattedSize} ${FILE_SIZE_UNITS[magnitude]}`;
};
