export enum BodyPartState {
  SAFE = "SAFE",
  HAZARD = "HAZARD",
  CAUTION = "CAUTION",
  NONE = "NONE",
}

export interface Size {
  width: number;
  height: number;
}

export type StateToColorType = {
  [key in BodyPartState]: string;
};
