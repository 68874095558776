import { api } from "./emptyApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    authControllerLogin: build.mutation<
      AuthControllerLoginApiResponse,
      AuthControllerLoginApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/auth`,
        method: "POST",
        body: queryArg.loginDto,
      }),
    }),
    authControllerSignup: build.mutation<
      AuthControllerSignupApiResponse,
      AuthControllerSignupApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/auth/signup`,
        method: "POST",
        body: queryArg.userCreateDto,
      }),
    }),
    usersControllerCreateNewUser: build.mutation<
      UsersControllerCreateNewUserApiResponse,
      UsersControllerCreateNewUserApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/users/create`,
        method: "POST",
        body: queryArg.userCreateDto,
      }),
    }),
    usersControllerUpdateExistingUser: build.mutation<
      UsersControllerUpdateExistingUserApiResponse,
      UsersControllerUpdateExistingUserApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/users/update/${queryArg.username}`,
        method: "PUT",
        body: queryArg.userUpdateDto,
      }),
    }),
    usersControllerUpdateUserProfile: build.mutation<
      UsersControllerUpdateUserProfileApiResponse,
      UsersControllerUpdateUserProfileApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/users/profile`,
        method: "PUT",
        body: queryArg.userUpdateDto,
      }),
    }),
    usersControllerSearchUsers: build.query<
      UsersControllerSearchUsersApiResponse,
      UsersControllerSearchUsersApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/users/search`,
        params: {
          username: queryArg.username,
          firstName: queryArg.firstName,
          lastName: queryArg.lastName,
          email: queryArg.email,
          isActive: queryArg.isActive,
          limitUsageMailSent: queryArg.limitUsageMailSent,
        },
      }),
    }),
    usersControllerSuspendUserByUsername: build.mutation<
      UsersControllerSuspendUserByUsernameApiResponse,
      UsersControllerSuspendUserByUsernameApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/users/suspend/${queryArg.username}`,
        method: "PUT",
      }),
    }),
    usersControllerActivateUserByUsername: build.mutation<
      UsersControllerActivateUserByUsernameApiResponse,
      UsersControllerActivateUserByUsernameApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/users/activate/${queryArg.username}`,
        method: "PUT",
      }),
    }),
    usersControllerAssignUserRole: build.mutation<
      UsersControllerAssignUserRoleApiResponse,
      UsersControllerAssignUserRoleApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/users/role`,
        method: "POST",
        body: queryArg.userRoleDto,
      }),
    }),
    usersPasswordControllerSendResetPasswordEmail: build.mutation<
      UsersPasswordControllerSendResetPasswordEmailApiResponse,
      UsersPasswordControllerSendResetPasswordEmailApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/password/password/reset/email`,
        method: "POST",
        body: queryArg.mailResetPasswordDto,
      }),
    }),
    usersPasswordControllerVerifyResetPasswordCredentials: build.query<
      UsersPasswordControllerVerifyResetPasswordCredentialsApiResponse,
      UsersPasswordControllerVerifyResetPasswordCredentialsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/password/password/reset/verify`,
        params: { email: queryArg.email, resetCode: queryArg.resetCode },
      }),
    }),
    usersPasswordControllerResetPassword: build.mutation<
      UsersPasswordControllerResetPasswordApiResponse,
      UsersPasswordControllerResetPasswordApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/password/password/reset`,
        method: "POST",
        body: queryArg.changePasswordDto,
        params: { email: queryArg.email, resetCode: queryArg.resetCode },
      }),
    }),
    usersPasswordControllerChangeUserPassword: build.mutation<
      UsersPasswordControllerChangeUserPasswordApiResponse,
      UsersPasswordControllerChangeUserPasswordApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/password/password/change`,
        method: "POST",
        body: queryArg.changePasswordDto,
      }),
    }),
    usersUsageLimitControllerGetUserUsage: build.query<
      UsersUsageLimitControllerGetUserUsageApiResponse,
      UsersUsageLimitControllerGetUserUsageApiArg
    >({
      query: () => ({ url: `/api/v1/usage/me` }),
    }),
    usersUsageLimitControllerGetAllUsersUsage: build.query<
      UsersUsageLimitControllerGetAllUsersUsageApiResponse,
      UsersUsageLimitControllerGetAllUsersUsageApiArg
    >({
      query: () => ({ url: `/api/v1/usage/all` }),
    }),
    usersUsageLimitControllerSetUserUsageLimit: build.mutation<
      UsersUsageLimitControllerSetUserUsageLimitApiResponse,
      UsersUsageLimitControllerSetUserUsageLimitApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/usage/limit`,
        method: "POST",
        body: queryArg.userLimitDto,
      }),
    }),
    usersUsageLimitControllerCheckUserLimitReached: build.query<
      UsersUsageLimitControllerCheckUserLimitReachedApiResponse,
      UsersUsageLimitControllerCheckUserLimitReachedApiArg
    >({
      query: () => ({ url: `/api/v1/usage/limit/me` }),
    }),
    tasksControllerGetUserTasksWithPagination: build.query<
      TasksControllerGetUserTasksWithPaginationApiResponse,
      TasksControllerGetUserTasksWithPaginationApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/tasks/with-pagination`,
        params: {
          page: queryArg.page,
          limit: queryArg.limit,
          search: queryArg.search,
          category: queryArg.category,
          tag: queryArg.tag,
          orderBy: queryArg.orderBy,
          order: queryArg.order,
          creator: queryArg.creator,
        },
      }),
    }),
    tasksControllerGetAdminTasksWithPagination: build.query<
      TasksControllerGetAdminTasksWithPaginationApiResponse,
      TasksControllerGetAdminTasksWithPaginationApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/tasks/admin-with-pagination`,
        params: {
          page: queryArg.page,
          limit: queryArg.limit,
          search: queryArg.search,
          category: queryArg.category,
          tag: queryArg.tag,
          orderBy: queryArg.orderBy,
          order: queryArg.order,
          creator: queryArg.creator,
        },
      }),
    }),
    tasksControllerUpdateUserTask: build.mutation<
      TasksControllerUpdateUserTaskApiResponse,
      TasksControllerUpdateUserTaskApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/tasks`,
        method: "PUT",
        body: queryArg.taskUpdateDto,
      }),
    }),
    tasksControllerDeleteTasks: build.mutation<
      TasksControllerDeleteTasksApiResponse,
      TasksControllerDeleteTasksApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/tasks`,
        method: "DELETE",
        body: queryArg.deleteTaskDto,
      }),
    }),
    tasksControllerGetTaskByIdForUser: build.query<
      TasksControllerGetTaskByIdForUserApiResponse,
      TasksControllerGetTaskByIdForUserApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/tasks/task`,
        params: { id: queryArg.id },
      }),
    }),
    tasksControllerDownloadTaskResultAsCsvWithApiBearer: build.query<
      TasksControllerDownloadTaskResultAsCsvWithApiBearerApiResponse,
      TasksControllerDownloadTaskResultAsCsvWithApiBearerApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/tasks/task/csv`,
        params: { id: queryArg.id },
      }),
    }),
    tasksControllerGetUserTagsForCurrentUser: build.query<
      TasksControllerGetUserTagsForCurrentUserApiResponse,
      TasksControllerGetUserTagsForCurrentUserApiArg
    >({
      query: () => ({ url: `/api/v1/tasks/get/tag` }),
    }),
    tasksControllerChangeTaskStateAsAdmin: build.mutation<
      TasksControllerChangeTaskStateAsAdminApiResponse,
      TasksControllerChangeTaskStateAsAdminApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/tasks/change-task-state`,
        method: "PATCH",
        body: queryArg.changeTaskStateDto,
      }),
    }),
    tasksControllerUpdateObservationAndRecommendation: build.mutation<
      TasksControllerUpdateObservationAndRecommendationApiResponse,
      TasksControllerUpdateObservationAndRecommendationApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/tasks/update-observation-recommendation`,
        method: "PATCH",
        body: queryArg.updateObservationRecommendationDto,
      }),
    }),
    categoryControllerCreateCategory: build.mutation<
      CategoryControllerCreateCategoryApiResponse,
      CategoryControllerCreateCategoryApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/category`,
        method: "POST",
        body: queryArg.createCategoryDto,
      }),
    }),
    categoryControllerDeleteCategory: build.mutation<
      CategoryControllerDeleteCategoryApiResponse,
      CategoryControllerDeleteCategoryApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/category`,
        method: "DELETE",
        body: queryArg.idDto,
      }),
    }),
    categoryControllerUpdateCategory: build.mutation<
      CategoryControllerUpdateCategoryApiResponse,
      CategoryControllerUpdateCategoryApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/category`,
        method: "PUT",
        body: queryArg.updateCategoryDto,
        params: { id: queryArg.id },
      }),
    }),
    categoryControllerGetAllCategories: build.query<
      CategoryControllerGetAllCategoriesApiResponse,
      CategoryControllerGetAllCategoriesApiArg
    >({
      query: () => ({ url: `/api/v1/category` }),
    }),
    categoryControllerCreateSystemCategory: build.mutation<
      CategoryControllerCreateSystemCategoryApiResponse,
      CategoryControllerCreateSystemCategoryApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/category/admin-system-category`,
        method: "POST",
        body: queryArg.createSystemCategoryDto,
      }),
    }),
    rolesControllerReadPermissions: build.query<
      RolesControllerReadPermissionsApiResponse,
      RolesControllerReadPermissionsApiArg
    >({
      query: () => ({ url: `/api/v1/roles/permissions` }),
    }),
    rolesControllerCreateRole: build.mutation<
      RolesControllerCreateRoleApiResponse,
      RolesControllerCreateRoleApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/roles`,
        method: "POST",
        body: queryArg.roleDto,
      }),
    }),
    rolesControllerUpdateRole: build.mutation<
      RolesControllerUpdateRoleApiResponse,
      RolesControllerUpdateRoleApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/roles`,
        method: "PUT",
        body: queryArg.updateRoleDto,
      }),
    }),
    rolesControllerFindRole: build.query<
      RolesControllerFindRoleApiResponse,
      RolesControllerFindRoleApiArg
    >({
      query: () => ({ url: `/api/v1/roles` }),
    }),
    rolesControllerDeleteRole: build.mutation<
      RolesControllerDeleteRoleApiResponse,
      RolesControllerDeleteRoleApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/roles`,
        method: "DELETE",
        body: queryArg.idDto,
      }),
    }),
    rolesControllerReadRole: build.query<
      RolesControllerReadRoleApiResponse,
      RolesControllerReadRoleApiArg
    >({
      query: (queryArg) => ({ url: `/api/v1/roles/${queryArg.roleId}` }),
    }),
    assessmentsControllerGetAssessments: build.query<
      AssessmentsControllerGetAssessmentsApiResponse,
      AssessmentsControllerGetAssessmentsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/assessments`,
        params: { id: queryArg.id },
      }),
    }),
    assessmentsControllerCreateAssessment: build.mutation<
      AssessmentsControllerCreateAssessmentApiResponse,
      AssessmentsControllerCreateAssessmentApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/assessments/assessments`,
        method: "POST",
        body: queryArg.assessmentDto,
      }),
    }),
    assessmentsControllerUpdateAssessment: build.mutation<
      AssessmentsControllerUpdateAssessmentApiResponse,
      AssessmentsControllerUpdateAssessmentApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/assessments/assessments`,
        method: "PUT",
        body: queryArg.updateAssessmentDto,
      }),
    }),
    assessmentsControllerDeleteAssessment: build.mutation<
      AssessmentsControllerDeleteAssessmentApiResponse,
      AssessmentsControllerDeleteAssessmentApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/assessments/assessments`,
        method: "DELETE",
        body: queryArg.idDto,
      }),
    }),
    poseControllerSaveTaskResultsInNewSchema: build.mutation<
      PoseControllerSaveTaskResultsInNewSchemaApiResponse,
      PoseControllerSaveTaskResultsInNewSchemaApiArg
    >({
      query: () => ({
        url: `/api/v1/pose/save-task-results-in-new-schema`,
        method: "POST",
      }),
    }),
    poseControllerGetPersonFramesAngles: build.query<
      PoseControllerGetPersonFramesAnglesApiResponse,
      PoseControllerGetPersonFramesAnglesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/personFrame/tasks/${queryArg.taskId}/persons/${queryArg.personId}/frames/angles`,
      }),
    }),
    poseControllerGetPersonsFrames: build.query<
      PoseControllerGetPersonsFramesApiResponse,
      PoseControllerGetPersonsFramesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/personFrame/tasks/${queryArg.id}/frames`,
      }),
    }),
    mediaControllerGetMediaUrl: build.query<
      MediaControllerGetMediaUrlApiResponse,
      MediaControllerGetMediaUrlApiArg
    >({
      query: (queryArg) => ({ url: `/api/v1/media/url/${queryArg.mediaName}` }),
    }),
    mediaControllerGetVideoStream: build.query<
      MediaControllerGetVideoStreamApiResponse,
      MediaControllerGetVideoStreamApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/media/video/${queryArg.mediaName}`,
      }),
    }),
    mediaControllerGetPoseUrl: build.query<
      MediaControllerGetPoseUrlApiResponse,
      MediaControllerGetPoseUrlApiArg
    >({
      query: (queryArg) => ({ url: `/api/v1/media/pose/${queryArg.taskId}` }),
    }),
    mediaControllerUploadVideo: build.mutation<
      MediaControllerUploadVideoApiResponse,
      MediaControllerUploadVideoApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/media`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type AuthControllerLoginApiResponse = /** status 200  */ LoginRo;
export type AuthControllerLoginApiArg = {
  loginDto: LoginDto;
};
export type AuthControllerSignupApiResponse = /** status 201  */ LoginRo;
export type AuthControllerSignupApiArg = {
  userCreateDto: UserCreateDto;
};
export type UsersControllerCreateNewUserApiResponse = /** status 201  */ UserRo;
export type UsersControllerCreateNewUserApiArg = {
  userCreateDto: UserCreateDto;
};
export type UsersControllerUpdateExistingUserApiResponse = unknown;
export type UsersControllerUpdateExistingUserApiArg = {
  username: string;
  userUpdateDto: UserUpdateDto;
};
export type UsersControllerUpdateUserProfileApiResponse =
  /** status 200  */ UserRo;
export type UsersControllerUpdateUserProfileApiArg = {
  userUpdateDto: UserUpdateDto;
};
export type UsersControllerSearchUsersApiResponse = /** status 200  */ UserRo[];
export type UsersControllerSearchUsersApiArg = {
  username?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  isActive?: boolean;
  limitUsageMailSent?: boolean;
};
export type UsersControllerSuspendUserByUsernameApiResponse = unknown;
export type UsersControllerSuspendUserByUsernameApiArg = {
  username: string;
};
export type UsersControllerActivateUserByUsernameApiResponse = unknown;
export type UsersControllerActivateUserByUsernameApiArg = {
  username: string;
};
export type UsersControllerAssignUserRoleApiResponse = unknown;
export type UsersControllerAssignUserRoleApiArg = {
  userRoleDto: UserRoleDto;
};
export type UsersPasswordControllerSendResetPasswordEmailApiResponse = unknown;
export type UsersPasswordControllerSendResetPasswordEmailApiArg = {
  mailResetPasswordDto: MailResetPasswordDto;
};
export type UsersPasswordControllerVerifyResetPasswordCredentialsApiResponse =
  unknown;
export type UsersPasswordControllerVerifyResetPasswordCredentialsApiArg = {
  email: string;
  resetCode: number;
};
export type UsersPasswordControllerResetPasswordApiResponse = unknown;
export type UsersPasswordControllerResetPasswordApiArg = {
  email: string;
  resetCode: number;
  changePasswordDto: ChangePasswordDto;
};
export type UsersPasswordControllerChangeUserPasswordApiResponse = unknown;
export type UsersPasswordControllerChangeUserPasswordApiArg = {
  changePasswordDto: ChangePasswordDto;
};
export type UsersUsageLimitControllerGetUserUsageApiResponse =
  /** status 200  */ UserUsageAndLimitRo;
export type UsersUsageLimitControllerGetUserUsageApiArg = void;
export type UsersUsageLimitControllerGetAllUsersUsageApiResponse =
  /** status 200  */ UsersUsageAndLimitRo;
export type UsersUsageLimitControllerGetAllUsersUsageApiArg = void;
export type UsersUsageLimitControllerSetUserUsageLimitApiResponse =
  /** status 200  */ UsersUsageAndLimitRo;
export type UsersUsageLimitControllerSetUserUsageLimitApiArg = {
  userLimitDto: UserLimitDto;
};
export type UsersUsageLimitControllerCheckUserLimitReachedApiResponse =
  /** status 200  */ LimitReachedRo;
export type UsersUsageLimitControllerCheckUserLimitReachedApiArg = void;
export type TasksControllerGetUserTasksWithPaginationApiResponse =
  /** status 200  */ TasksRo;
export type TasksControllerGetUserTasksWithPaginationApiArg = {
  /** Page number for pagination */
  page?: number;
  /** Number of items per page */
  limit?: number;
  /** Search term to filter tasks by name */
  search?: string;
  /** Category ID to filter tasks */
  category?: string;
  /** Tag ID to filter tasks */
  tag?: string;
  /** Field to sort results by */
  orderBy?: string;
  /** Sort direction (asc or desc) */
  order?: "asc" | "desc";
  /** Filter tasks by creator username */
  creator?: string;
};
export type TasksControllerGetAdminTasksWithPaginationApiResponse =
  /** status 200  */ TasksRo;
export type TasksControllerGetAdminTasksWithPaginationApiArg = {
  /** Page number for pagination */
  page?: number;
  /** Number of items per page */
  limit?: number;
  /** Search term to filter tasks by name */
  search?: string;
  /** Category ID to filter tasks */
  category?: string;
  /** Tag ID to filter tasks */
  tag?: string;
  /** Field to sort results by */
  orderBy?: string;
  /** Sort direction (asc or desc) */
  order?: "asc" | "desc";
  /** Filter tasks by creator username */
  creator?: string;
};
export type TasksControllerUpdateUserTaskApiResponse = unknown;
export type TasksControllerUpdateUserTaskApiArg = {
  taskUpdateDto: TaskUpdateDto;
};
export type TasksControllerDeleteTasksApiResponse = unknown;
export type TasksControllerDeleteTasksApiArg = {
  deleteTaskDto: DeleteTaskDto;
};
export type TasksControllerGetTaskByIdForUserApiResponse =
  /** status 200  */ TaskRo;
export type TasksControllerGetTaskByIdForUserApiArg = {
  id: string;
};
export type TasksControllerDownloadTaskResultAsCsvWithApiBearerApiResponse =
  unknown;
export type TasksControllerDownloadTaskResultAsCsvWithApiBearerApiArg = {
  id: string;
};
export type TasksControllerGetUserTagsForCurrentUserApiResponse =
  /** status 200  */ TagRo[];
export type TasksControllerGetUserTagsForCurrentUserApiArg = void;
export type TasksControllerChangeTaskStateAsAdminApiResponse = unknown;
export type TasksControllerChangeTaskStateAsAdminApiArg = {
  changeTaskStateDto: ChangeTaskStateDto;
};
export type TasksControllerUpdateObservationAndRecommendationApiResponse =
  unknown;
export type TasksControllerUpdateObservationAndRecommendationApiArg = {
  updateObservationRecommendationDto: UpdateObservationRecommendationDto;
};
export type CategoryControllerCreateCategoryApiResponse =
  /** status 200  */ CreateCategoryRo;
export type CategoryControllerCreateCategoryApiArg = {
  createCategoryDto: CreateCategoryDto;
};
export type CategoryControllerDeleteCategoryApiResponse = unknown;
export type CategoryControllerDeleteCategoryApiArg = {
  idDto: IdDto;
};
export type CategoryControllerUpdateCategoryApiResponse = unknown;
export type CategoryControllerUpdateCategoryApiArg = {
  id: string;
  updateCategoryDto: UpdateCategoryDto;
};
export type CategoryControllerGetAllCategoriesApiResponse =
  /** status 200  */ CategoriesRo;
export type CategoryControllerGetAllCategoriesApiArg = void;
export type CategoryControllerCreateSystemCategoryApiResponse =
  /** status 200  */ CreateCategoryRo;
export type CategoryControllerCreateSystemCategoryApiArg = {
  createSystemCategoryDto: CreateSystemCategoryDto;
};
export type RolesControllerReadPermissionsApiResponse =
  /** status 200  */ PermissionViewRo[];
export type RolesControllerReadPermissionsApiArg = void;
export type RolesControllerCreateRoleApiResponse = /** status 201  */ RoleRo;
export type RolesControllerCreateRoleApiArg = {
  roleDto: RoleDto;
};
export type RolesControllerUpdateRoleApiResponse = unknown;
export type RolesControllerUpdateRoleApiArg = {
  updateRoleDto: UpdateRoleDto;
};
export type RolesControllerFindRoleApiResponse = /** status 200  */ RolesRo;
export type RolesControllerFindRoleApiArg = void;
export type RolesControllerDeleteRoleApiResponse = unknown;
export type RolesControllerDeleteRoleApiArg = {
  idDto: IdDto;
};
export type RolesControllerReadRoleApiResponse = /** status 200  */ RoleRo;
export type RolesControllerReadRoleApiArg = {
  roleId: string;
};
export type AssessmentsControllerGetAssessmentsApiResponse =
  /** status 200  */ AssessmentRo[];
export type AssessmentsControllerGetAssessmentsApiArg = {
  id: string;
};
export type AssessmentsControllerCreateAssessmentApiResponse = unknown;
export type AssessmentsControllerCreateAssessmentApiArg = {
  assessmentDto: AssessmentDto;
};
export type AssessmentsControllerUpdateAssessmentApiResponse = unknown;
export type AssessmentsControllerUpdateAssessmentApiArg = {
  updateAssessmentDto: UpdateAssessmentDto;
};
export type AssessmentsControllerDeleteAssessmentApiResponse = unknown;
export type AssessmentsControllerDeleteAssessmentApiArg = {
  idDto: IdDto;
};
export type PoseControllerSaveTaskResultsInNewSchemaApiResponse = unknown;
export type PoseControllerSaveTaskResultsInNewSchemaApiArg = void;
export type PoseControllerGetPersonFramesAnglesApiResponse =
  /** status 200  */ AnglesRo[];
export type PoseControllerGetPersonFramesAnglesApiArg = {
  /** ID of the task to retrieve angles for */
  taskId: string;
  /** ID of the person or "best" for best frames */
  personId?: string;
};
export type PoseControllerGetPersonsFramesApiResponse =
  /** status 200  */ PersonFrameRo[];
export type PoseControllerGetPersonsFramesApiArg = {
  id: string;
};
export type MediaControllerGetMediaUrlApiResponse = /** status 200  */ UrlRo;
export type MediaControllerGetMediaUrlApiArg = {
  mediaName: string;
};
export type MediaControllerGetVideoStreamApiResponse = unknown;
export type MediaControllerGetVideoStreamApiArg = {
  mediaName: string;
};
export type MediaControllerGetPoseUrlApiResponse = /** status 200  */ string;
export type MediaControllerGetPoseUrlApiArg = {
  taskId: string;
};
export type MediaControllerUploadVideoApiResponse =
  /** status 201  */ MinimalTaskRo;
export type MediaControllerUploadVideoApiArg = {
  body: {
    file: Blob;
    categoryId: string;
    name: string;
    description?: string;
    tagName?: string;
    blurFace: boolean;
    blurBack: boolean;
    multiTarget: boolean;
    useCameraZ: boolean;
  };
};
export type UserLimitRo = {
  count?: number;
  duration?: number;
  date?: string;
  lastUsageCount: number;
  lastUsageDuration: number;
  lastUsageDate?: string;
};
export type UserRo = {
  username: string;
  firstName: string;
  lastName: string;
  email: string;
  roleNames: string[];
  limit: UserLimitRo;
  isActive: boolean;
};
export type LoginRo = {
  token: string;
  user: UserRo;
};
export type LoginDto = {
  username: string;
  password: string;
};
export type UserCreateDto = {
  username: string;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
};
export type UserUpdateDto = {
  firstName?: string;
  lastName?: string;
  email?: string;
};
export type UserRoleDto = {
  username: string;
  roleIds: string[];
};
export type MailResetPasswordDto = {
  email: string;
  callbackUrl: string;
};
export type ChangePasswordDto = {
  password: string;
};
export type UserUsageRo = {
  /** Total duration in seconds of all processed videos */
  duration?: number;
  /** Total number of tasks processed */
  count?: number;
};
export type UserUsageAndLimitRo = {
  creator: string;
  usage: UserUsageRo;
  limit: UserLimitRo;
  userCreateDate: string;
};
export type UsersUsageAndLimitRo = {
  usersUsageAndLimit: UserUsageAndLimitRo[];
};
export type UserLimitDto = {
  count?: number;
  duration?: number;
  date?: string;
  username: string;
};
export type LimitReachedRo = {
  reached: boolean;
};
export type MinimalCategoryRo = {
  id: string;
  name: string;
};
export type TagRo = {
  /** Unique identifier of the tag */
  id: string;
  /** Name of the tag used for organizing tasks */
  name: string;
};
export type MinimalTaskRo = {
  /** Unique identifier for the task */
  id: string;
  /** Username of the task creator */
  creator: string;
  /** Original filename of the uploaded video */
  fileName: string;
  /** Current state of the task processing */
  state:
    | "DETECT_KEYPOINTS_PENDING"
    | "DETECTING_KEYPOINTS"
    | "CALCULATE_ANGLES_PENDING"
    | "CALCULATING_ANGLES"
    | "GENERATE_VIDEO_PENDING"
    | "GENERATING_VIDEO"
    | "READY"
    | "ERROR";
  /** Creation timestamp of the task */
  createdAt: string;
  /** Category information in minimal form */
  category?: MinimalCategoryRo;
  /** Whether the task has been marked as deleted */
  isDeleted: boolean;
  /** Name of the task */
  name: string;
  /** Detailed description of the task */
  description: string;
  /** Associated tag information */
  tag?: TagRo;
  /** Start time of task processing */
  taskStart: string;
  /** End time of task processing */
  taskEnd: string;
  /** Duration of the video in seconds */
  duration: number;
  /** Output filename after processing */
  outFileName: string;
  /** Overall risk score calculated from ergonomic assessment */
  riskScore?: number;
  /** Observations about ergonomic risks */
  observation: string;
  /** Recommendations for improving ergonomics */
  recommendation: string;
};
export type PaginationMeta = {
  /** Total number of items available */
  total: number;
  /** Current page number */
  page: number;
  /** Number of items per page */
  limit: number;
};
export type TasksRo = {
  /** Array of tasks with minimal information */
  tasks: MinimalTaskRo[];
  /** Pagination metadata including total count and current page info */
  meta?: PaginationMeta;
};
export type TaskUpdateDto = {
  /** ID of the task to update */
  id: string;
  /** New tag name to assign to the task */
  tag?: string;
  /** New description for the task */
  description?: string;
  /** New name for the task */
  name: string;
};
export type DeleteTaskDto = {
  /** Array of task IDs to delete */
  ids: string[];
  /** Whether to permanently delete the tasks (admin only) */
  permanent: boolean;
};
export type ThresholdDto = {
  /** Marks the beginning of the first caution zone */
  threshold1: number;
  /** Marks the beginning of the safe zone */
  threshold2: number;
  /** Marks the end of the safe zone */
  threshold3: number;
  /** Marks the end of the second caution zone */
  threshold4: number;
};
export type SettingDto = {
  neck: ThresholdDto;
  back: ThresholdDto;
  rightKnee: ThresholdDto;
  leftKnee: ThresholdDto;
  rightUpperArm: ThresholdDto;
  leftUpperArm: ThresholdDto;
  rightLowerArm: ThresholdDto;
  leftLowerArm: ThresholdDto;
  rightWrist: ThresholdDto;
  leftWrist: ThresholdDto;
};
export type ResultRo = {
  /** Neck angle measurement in degrees */
  neck?: number;
  /** Back angle measurement in degrees */
  back?: number;
  /** Right knee angle measurement in degrees */
  rightKnee?: number;
  /** Left knee angle measurement in degrees */
  leftKnee?: number;
  /** Right upper arm angle measurement in degrees */
  rightUpperArm?: number;
  /** Left upper arm angle measurement in degrees */
  leftUpperArm?: number;
  /** Right lower arm angle measurement in degrees */
  rightLowerArm?: number;
  /** Left lower arm angle measurement in degrees */
  leftLowerArm?: number;
  /** Right wrist angle measurement in degrees */
  rightWrist?: number;
  /** Left wrist angle measurement in degrees */
  leftWrist?: number;
};
export type TaskRo = {
  /** Unique identifier of the task */
  id: string;
  /** Username of the task creator */
  creator: string;
  /** Original filename of the uploaded video */
  fileName: string;
  /** Current processing state of the task */
  state:
    | "DETECT_KEYPOINTS_PENDING"
    | "DETECTING_KEYPOINTS"
    | "CALCULATE_ANGLES_PENDING"
    | "CALCULATING_ANGLES"
    | "GENERATE_VIDEO_PENDING"
    | "GENERATING_VIDEO"
    | "READY"
    | "ERROR";
  /** Timestamp when the task was created */
  createdAt: string;
  /** Associated category information in minimal form */
  category?: MinimalCategoryRo;
  /** Whether the task has been marked as deleted */
  isDeleted: boolean;
  /** Name/title of the task */
  name: string;
  /** Detailed description of the task */
  description: string;
  /** Associated tag information for task organization */
  tag?: TagRo;
  setting: SettingDto;
  /** Filename of the uploaded original video */
  inFileName: string;
  /** Filename of the uploaded proceeded video */
  outFileName: string;
  /** Whether to use camera Z-axis data for angle calculations */
  useCameraZ: boolean;
  /** Whether pose data is available for this task */
  havePose: boolean;
  /** Whether the video contains multiple target persons */
  multiTarget: boolean;
  /** Task processing start timestamp */
  taskStart: string;
  /** Task processing completion timestamp */
  taskEnd: string;
  /** Duration of the video in seconds */
  duration: number;
  /** Array of frame-by-frame pose analysis results */
  result: ResultRo[];
  /** Overall ergonomic risk score calculated from the analysis */
  riskScore?: number;
  /** Total number of frames in the video */
  videoFrameLength?: number;
  /** Observations about ergonomic risks and issues */
  observation: string;
  /** Recommendations for improving ergonomics */
  recommendation: string;
};
export type ChangeTaskStateDto = {
  /** Unique identifier of the task to update */
  id: string;
  /** New state to set for the task (e.g., READY, ERROR, ...) */
  state:
    | "DETECT_KEYPOINTS_PENDING"
    | "DETECTING_KEYPOINTS"
    | "CALCULATE_ANGLES_PENDING"
    | "CALCULATING_ANGLES"
    | "GENERATE_VIDEO_PENDING"
    | "GENERATING_VIDEO"
    | "READY"
    | "ERROR";
};
export type UpdateObservationRecommendationDto = {
  /** ID of the task to update */
  id: string;
  /** Updated ergonomic observations */
  observation: string;
  /** Updated ergonomic recommendations */
  recommendation: string;
};
export type CreateCategoryRo = {
  id: string;
};
export type CreateCategoryDto = {
  name: string;
  setting: SettingDto;
};
export type IdDto = {
  id: string;
};
export type UpdateCategoryDto = {
  name: string;
  setting: SettingDto;
};
export type CategoryRo = {
  id: string;
  creator: string;
  name: string;
  setting: SettingDto;
  isSystemCategory: boolean;
};
export type CategoriesRo = {
  categories: CategoryRo[];
};
export type CreateSystemCategoryDto = {
  name: string;
  setting: SettingDto;
  isSystemCategory?: boolean;
};
export type PermissionViewRo = {
  id: string;
  category: string;
  group: string;
  name: string;
};
export type RoleRo = {
  id: string;
  name: string;
  permissions: "ADMIN"[];
};
export type RoleDto = {
  name: string;
  permissions: "ADMIN"[];
};
export type UpdateRoleDto = {
  id: string;
  name: string;
  permissions: "ADMIN"[];
};
export type RolesRo = {
  roles: RoleRo[];
};
export type NeckAdjustDto = {
  twisted: boolean;
  sideBending: boolean;
};
export type TrunkAdjustDto = {
  twisted: boolean;
  sideBending: boolean;
};
export type UpperArmAdjustDto = {
  raisedShoulder: boolean;
  abductedUpperArm: boolean;
  supportedArm: boolean;
};
export type ActivityDto = {
  bodyPartHeldLong: boolean;
  repeatedSmallRangeAction: boolean;
  rapidLargeRangeChange: boolean;
};
export type RebaDto = {
  neckAngle: "ANGLE_0_MINUS" | "ANGLE_0_20" | "ANGLE_20_PLUS";
  neckAdjust: NeckAdjustDto;
  trunkAngle:
    | "ANGLE_0_MINUS"
    | "ANGLE_0_10"
    | "ANGLE_10_20"
    | "ANGLE_20_60"
    | "ANGLE_60_PLUS";
  trunkAdjust: TrunkAdjustDto;
  legDown: boolean;
  legAngle?: "ANGLE_0_30" | "ANGLE_30_60" | "ANGLE_60_PLUS";
  forceMode: "LOAD_0_11_LBS" | "LOAD_11_22_LBS" | "LOAD_22_PLUS_LBS";
  shock: boolean;
  upperArmAngle:
    | "ANGLE__20_MINUS"
    | "ANGLE__20_20"
    | "ANGLE_20_45"
    | "ANGLE_45_90"
    | "ANGLE_90_PLUS";
  upperArmAdjust: UpperArmAdjustDto;
  lowerArmAngle: "ANGLE_0_60_OR_100_PLUS" | "ANGLE_60_100";
  wristAngle: "ANGLE__15_15" | "ANGLE_GT_15";
  bentWrist: boolean;
  couplingMode: "GOOD" | "FAIR" | "POOR" | "UNACCEPTABLE";
  activity: ActivityDto;
};
export type RulaDto = {
  neckAngle: "ANGLE_0_MINUS" | "ANGLE_0_10" | "ANGLE_10_20" | "ANGLE_20_PLUS";
  neckAdjust: NeckAdjustDto;
  trunkAngle: "ANGLE_0_10" | "ANGLE_10_20" | "ANGLE_20_60" | "ANGLE_60_PLUS";
  trunkAdjust: TrunkAdjustDto;
  legSupported: boolean;
  muscleUse: boolean;
  forceMode:
    | "LOAD_0_4_LBS"
    | "LOAD_4_22_LBS"
    | "LOAD_4_22_LBS_REPEATED"
    | "LOAD_22_PLUS_LBS";
  upperArmAngle:
    | "ANGLE__20_MINUS"
    | "ANGLE__20_20"
    | "ANGLE_20_45"
    | "ANGLE_45_90"
    | "ANGLE_90_PLUS";
  upperArmAdjust: UpperArmAdjustDto;
  lowerArmAngle: "ANGLE_0_60_OR_100_PLUS" | "ANGLE_60_100";
  lowerArmAdjust: boolean;
  wristAngle: "ANGLE_0" | "ANGLE__15_15" | "ANGLE_GT_15";
  bentWrist: boolean;
  wristTwist: boolean;
};
export type LiftLowerTaskDataDto = {
  frequency: number;
  handCoupling: "GOOD" | "FAIR" | "POOR";
  startHandHeight: number;
  endHandHeight: number;
  startHandDistance: number;
  endHandDistance: number;
};
export type PushPullCarryTaskDataDto = {
  frequency: number;
  horizontalDistance: number;
  verticalHandHeight: number;
};
export type LiftLowerCarryLoadDto = {
  objectWeight: number;
};
export type PushPullLoadDto = {
  initialForce: number;
  sustainedForce: number;
};
export type MmhDto = {
  /** Type of manual material handling task being assessed */
  mode: "LIFT" | "LOWER" | "PUSH" | "PULL" | "CARRY";
  /** Task-specific data including measurements and frequencies */
  data: LiftLowerTaskDataDto | PushPullCarryTaskDataDto;
  /** Load-related measurements including weight or force requirements */
  load: LiftLowerCarryLoadDto | PushPullLoadDto;
};
export type AssessmentDataDto = {
  reba?: RebaDto;
  rula?: RulaDto;
  mmh?: MmhDto;
};
export type AssessmentRo = {
  /** Unique identifier of the assessment */
  id: string;
  /** Frame index in the video where assessment was made */
  frameIndex?: number;
  /** Assessment method used (REBA, RULA, or MMH) */
  method: "REBA" | "RULA" | "MMH";
  /** Username of the assessment creator */
  creator: string;
  /** Optional descriptive name of the assessment */
  name?: string;
  /** Target person identifier in multi-person assessments */
  targetId?: string;
  /** Detailed assessment data containing measurements and calculations */
  data: AssessmentDataDto;
};
export type AssessmentDto = {
  /** The ID of the task this assessment belongs to */
  taskId: string;
  /** The assessment method to be used (REBA, RULA, or MMH) */
  method: "REBA" | "RULA" | "MMH";
  /** Optional name for the assessment */
  name?: string;
  /** Frame index in the video where the assessment is made */
  frameIndex?: number;
  /** ID of the target person in the frame */
  targetId?: string;
  /** Assessment data containing REBA, RULA, or MMH specific measurements */
  data: AssessmentDataDto;
};
export type UpdateAssessmentDto = {
  /** ID of the assessment to update */
  id: string;
  /** Assessment method (REBA, RULA, or MMH) */
  method: "REBA" | "RULA" | "MMH";
  /** New name for the assessment */
  name?: string;
  /** New frame index for the assessment */
  frameIndex?: number;
  /** New target person ID */
  targetId?: string;
  /** Updated assessment data */
  data: AssessmentDataDto;
};
export type AnglesRo = {
  /** Neck angle in degrees */
  neck?: number;
  /** Neck angles in anatomical planes */
  neckPlane?: object;
  /** Back angle in degrees */
  back?: number;
  /** Back angles in anatomical planes */
  backPlane?: object;
  /** Right knee angle in degrees */
  rightKnee?: number;
  /** Right knee angles in anatomical planes */
  rightKneePlane?: object;
  /** Left knee angle in degrees */
  leftKnee?: number;
  /** Left knee angles in anatomical planes */
  leftKneePlane?: object;
  /** Right upper arm angle in degrees */
  rightUpperArm?: number;
  /** Right upper arm angles in anatomical planes */
  rightUpperArmPlane?: object;
  /** Left upper arm angle in degrees */
  leftUpperArm?: number;
  /** Left upper arm angles in anatomical planes */
  leftUpperArmPlane?: object;
  /** Right lower arm angle in degrees */
  rightLowerArm?: number;
  /** Right lower arm angles in anatomical planes */
  rightLowerArmPlane?: object;
  /** Left lower arm angle in degrees */
  leftLowerArm?: number;
  /** Left lower arm angles in anatomical planes */
  leftLowerArmPlane?: object;
  /** Right wrist angle in degrees */
  rightWrist?: number;
  /** Right wrist angles in anatomical planes */
  rightWristPlane?: object;
  /** Left wrist angle in degrees */
  leftWrist?: number;
  /** Left wrist angles in anatomical planes */
  leftWristPlane?: object;
};
export type Keypoints2DRo = {
  /** Body keypoints coordinates */
  body: number[][];
  /** Face keypoints coordinates */
  face: number[][];
  /** Hands keypoints coordinates */
  hands: number[][];
  /** Legs keypoints coordinates */
  legs: number[][];
};
export type PersonFrameRo = {
  /** Frame index number */
  frameIndex: number;
  /** Bounding box coordinates [x, y, width, height] */
  bbox?: number[];
  /** 3D keypoints array */
  keypoints?: number[][];
  /** 2D keypoints object */
  keypoints2d?: Keypoints2DRo;
  /** Angles data */
  angles?: AnglesRo;
  /** Whether this is the best frame */
  isBest?: boolean;
};
export type UrlRo = {
  url: string;
};
export const {
  useAuthControllerLoginMutation,
  useAuthControllerSignupMutation,
  useUsersControllerCreateNewUserMutation,
  useUsersControllerUpdateExistingUserMutation,
  useUsersControllerUpdateUserProfileMutation,
  useUsersControllerSearchUsersQuery,
  useUsersControllerSuspendUserByUsernameMutation,
  useUsersControllerActivateUserByUsernameMutation,
  useUsersControllerAssignUserRoleMutation,
  useUsersPasswordControllerSendResetPasswordEmailMutation,
  useUsersPasswordControllerVerifyResetPasswordCredentialsQuery,
  useUsersPasswordControllerResetPasswordMutation,
  useUsersPasswordControllerChangeUserPasswordMutation,
  useUsersUsageLimitControllerGetUserUsageQuery,
  useUsersUsageLimitControllerGetAllUsersUsageQuery,
  useUsersUsageLimitControllerSetUserUsageLimitMutation,
  useUsersUsageLimitControllerCheckUserLimitReachedQuery,
  useTasksControllerGetUserTasksWithPaginationQuery,
  useTasksControllerGetAdminTasksWithPaginationQuery,
  useTasksControllerUpdateUserTaskMutation,
  useTasksControllerDeleteTasksMutation,
  useTasksControllerGetTaskByIdForUserQuery,
  useTasksControllerDownloadTaskResultAsCsvWithApiBearerQuery,
  useTasksControllerGetUserTagsForCurrentUserQuery,
  useTasksControllerChangeTaskStateAsAdminMutation,
  useTasksControllerUpdateObservationAndRecommendationMutation,
  useCategoryControllerCreateCategoryMutation,
  useCategoryControllerDeleteCategoryMutation,
  useCategoryControllerUpdateCategoryMutation,
  useCategoryControllerGetAllCategoriesQuery,
  useCategoryControllerCreateSystemCategoryMutation,
  useRolesControllerReadPermissionsQuery,
  useRolesControllerCreateRoleMutation,
  useRolesControllerUpdateRoleMutation,
  useRolesControllerFindRoleQuery,
  useRolesControllerDeleteRoleMutation,
  useRolesControllerReadRoleQuery,
  useAssessmentsControllerGetAssessmentsQuery,
  useAssessmentsControllerCreateAssessmentMutation,
  useAssessmentsControllerUpdateAssessmentMutation,
  useAssessmentsControllerDeleteAssessmentMutation,
  usePoseControllerSaveTaskResultsInNewSchemaMutation,
  usePoseControllerGetPersonFramesAnglesQuery,
  usePoseControllerGetPersonsFramesQuery,
  useMediaControllerGetMediaUrlQuery,
  useMediaControllerGetVideoStreamQuery,
  useMediaControllerGetPoseUrlQuery,
  useMediaControllerUploadVideoMutation,
} = injectedRtkApi;
