import { BaseQueryFn } from "@reduxjs/toolkit/query";
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { toast } from "react-toastify";
import { logoutAction } from "../slice/authSlice";
import { RootStateType } from "../store";

interface EnvConfig {
  REACT_APP_PRODUCTION_URL_V1: string;
  REACT_APP_DEVELOPMENT_URL_V1: string;
}

declare global {
  interface Window {
    _env_: EnvConfig;
  }
}

export const getBaseUrl = (): string => {
  const env =
    process.env.NODE_ENV === "development" ? "DEVELOPMENT" : "PRODUCTION";
  return (window._env_ || process.env)[`REACT_APP_${env}_URL_V1`];
};

const createHeaders = (
  authToken?: string,
  customHeaders?: AxiosRequestConfig["headers"],
): AxiosRequestConfig["headers"] => ({
  ...(customHeaders || {}),
  ...(authToken && { authorization: `Bearer ${authToken}` }),
});

interface ErrorResponse {
  status?: number;
  data: any;
}

const handleErrorResponse = (
  error: AxiosError,
  dispatch: any,
): { error: ErrorResponse } => {
  const errorResponse: ErrorResponse = {
    status: error.response?.status,
    data: error.response?.data || error.message,
  };

  console.log("RTK query error handler:", errorResponse);

  if (errorResponse.data === "canceled") {
    return { error: errorResponse };
  }

  if (errorResponse.status === 401) {
    dispatch(logoutAction());
  } else if (errorResponse.status !== 404) {
    const errorMessage = errorResponse.data?.message
      ? `${errorResponse.data.message}${errorResponse.status ? ` (status code: ${errorResponse.status})` : ""}`
      : "Something went wrong, please try again later.";
    toast.error(errorMessage);
  }

  return { error: errorResponse };
};

export const baseQuery: BaseQueryFn<
  {
    url: string;
    method?: AxiosRequestConfig["method"];
    body?: AxiosRequestConfig["data"];
    params?: AxiosRequestConfig["params"];
    headers?: AxiosRequestConfig["headers"];
    abortController?: AbortController;
    onUploadProgress?: AxiosRequestConfig["onUploadProgress"];
  },
  unknown,
  unknown
> = async (args, { getState, dispatch }) => {
  const { auth } = getState() as RootStateType;
  const {
    url,
    method = "GET",
    body,
    params,
    headers,
    abortController,
    onUploadProgress,
  } = args;

  try {
    const config: AxiosRequestConfig = {
      url: getBaseUrl() + url,
      method,
      data: body,
      params,
      headers: createHeaders(auth?.token, headers),
      signal: abortController?.signal,
      onUploadProgress,
    };

    const response: AxiosResponse = await axios(config);
    return { data: response.data };
  } catch (error) {
    return handleErrorResponse(error as AxiosError, dispatch);
  }
};
