import { FC, useState } from "react";
import { Grid, Stack, Button, Box } from "@mui/material";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import Dropzone from "../Dropzone";
import { useCustomMediaControllerUploadVideoMutation } from "src/app/services/api";
import { tagModifier } from "../tagModifier";
import { nameIdType } from "src/components/atoms/AutocompleteWithAdd";
import { removeFileExtension } from "../utils";

type UploadStepProps = {
  initialValues: {
    name: string;
    categoryId: string;
    tagName: string | nameIdType | null;
    description: string;
    blurFace: boolean;
    blurBack: boolean;
    multiTarget: boolean;
  };
  onCompleted: () => void;
  handleBack: () => void;
};

type FileUploadState = {
  file: File;
  progress: number;
  status: "uploading" | "success" | "error";
  abortController: AbortController;
};

export const UploadStep: FC<UploadStepProps> = ({
  initialValues,
  onCompleted,
  handleBack,
}) => {
  const [fileUploadStates, setFileUploadStates] = useState<FileUploadState[]>(
    [],
  );
  const [isUploading, setIsUploading] = useState(false);
  const [createTask] = useCustomMediaControllerUploadVideoMutation();
  const navigate = useNavigate();

  const uploadFile = async (
    file: File,
    index: number,
    abortController: AbortController,
  ) => {
    const cleanFileName = removeFileExtension(file.name);
    const params = {
      ...initialValues,
      file,
      name: `${initialValues.name}${cleanFileName ? ` - ${cleanFileName}` : ""}`,
      tagName: tagModifier(initialValues.tagName),
    };
    const formData = new FormData();
    Object.entries(params).forEach(([key, value]) => {
      formData.append(key, value as string);
    });

    try {
      await createTask({
        body: formData as any,
        abortController,
        onUploadProgress: (event) => {
          setFileUploadStates((prev) =>
            prev.map((state, i) =>
              i === index
                ? {
                    ...state,
                    progress: (event.loaded / (event.total || 1)) * 100,
                    status: "uploading",
                  }
                : state,
            ),
          );
        },
      }).unwrap();
      setFileUploadStates((prev) =>
        prev.map((state, i) =>
          i === index ? { ...state, status: "success" } : state,
        ),
      );
    } catch (error) {
      if (abortController.signal.aborted) {
        console.log(`Upload aborted: ${file.name}`);
      } else {
        setFileUploadStates((prev) =>
          prev.map((state, i) =>
            i === index ? { ...state, status: "error" } : state,
          ),
        );
        throw error;
      }
    }
  };

  const submitHandler = async () => {
    setIsUploading(true);
    try {
      await Promise.all(
        fileUploadStates.map(({ file, abortController }, index) =>
          uploadFile(file, index, abortController),
        ),
      );
      onCompleted();
      navigate("/results");
    } catch (error) {
      toast.error("Failed to upload file");
    } finally {
      setIsUploading(false);
    }
  };

  const handleCancelAll = () => {
    fileUploadStates.forEach(({ abortController }) => abortController.abort());
    setFileUploadStates([]);
    handleBack();
  };

  const handleFilesChange = (files: File[]) => {
    setFileUploadStates(
      files.map((file) => ({
        file,
        progress: 0,
        status: "uploading",
        abortController: new AbortController(),
      })),
    );
  };

  const handleCancel = (index: number) => {
    const { abortController } = fileUploadStates[index];
    abortController.abort();
    setFileUploadStates((prev) => prev.filter((_, i) => i !== index));
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Stack spacing={2}>
          <Dropzone
            onChange={handleFilesChange}
            multiple
            fileUploadStates={fileUploadStates}
            onCancel={handleCancel}
          />
          <Box>
            <Stack
              direction="row"
              sx={{ width: "100%" }}
              alignItems="center"
              spacing={2}
            >
              <Button
                disabled={isUploading || fileUploadStates.length === 0}
                onClick={submitHandler}
                sx={{ width: "100%", maxWidth: "330px", fontWeight: 700 }}
                variant="contained"
              >
                {isUploading ? "Uploading..." : "Submit"}
              </Button>
              <Button onClick={handleCancelAll}>Back</Button>
            </Stack>
          </Box>
        </Stack>
      </Grid>
    </Grid>
  );
};
