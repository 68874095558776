import { FC, createContext, useState, useEffect } from "react";
import { HelpDialog } from "src/components/organisms/assessment/help/HelpDialog";
import { SelectAssessmentMethodDialog } from "src/components/molecules/assessment/SelectAssessmentMethodDialog";
import { AssessmentDataDto } from "src/app/services/generatedApi";

type ResetPropsType = {
  id?: string;
  name?: string;
  frameIndex?: number;
  method: "REBA" | "RULA" | "MMH";
  assessmentDataDto?: AssessmentDataDto;
  targetId?: string;
};

type AssessmentContextType = {
  id?: string;
  removeId: () => void;
  targetId?: string;
  setTargetId: (targetId?: string) => void;
  name?: string;
  setName: (name?: string) => void;
  frameIndex?: number;
  setSelectedFrame: (frameIndex?: number) => void;
  assessmentDataDto?: AssessmentDataDto;
  setAssessmentDataDto: (assessmentDataDto: AssessmentDataDto) => void;
  reset: (props?: ResetPropsType) => void;
  assessmentPageIndex: number;
  setAssessmentPageIndex: (assessmentPageIndex: number) => void;
  setHelpMethod: (helpMethod?: "REBA" | "RULA" | "MMH") => void;
  setOpenSelectAssessmentMethodDialog: (
    openSelectAssessmentMethod: boolean,
  ) => void;
  selectedMethod?: "REBA" | "RULA" | "MMH";
  selectMethod: (method: "REBA" | "RULA" | "MMH") => void;
};

export const AssessmentContext = createContext<AssessmentContextType>({
  setTargetId: (targetId?: string) => {},
  removeId: () => {},
  setName: (name?: string) => {},
  frameIndex: 0,
  setSelectedFrame: (frameIndex?: number) => {},
  setAssessmentDataDto: (assessmentDataDto?: AssessmentDataDto) => {},
  reset: (props?: ResetPropsType) => {},
  assessmentPageIndex: 0,
  setAssessmentPageIndex: (assessmentPageIndex: number) => {},
  setHelpMethod: (helpMethod?: "REBA" | "RULA" | "MMH") => {},
  setOpenSelectAssessmentMethodDialog: (
    openSelectAssessmentMethod: boolean,
  ) => {},
  selectMethod: (method: "REBA" | "RULA" | "MMH") => {},
});

const AssessmentContextProvider: FC<{
  children: React.ReactNode;
  assessmentPageIndex: number;
  setAssessmentPageIndex: (assessmentPageIndex: number) => void;
}> = ({ children, assessmentPageIndex, setAssessmentPageIndex }) => {
  const [id, setId] = useState<string>();
  const [targetId, setTargetId] = useState<string>();
  const [name, setName] = useState<string>();
  const [selectedMethod, setSelectedMethod] = useState<
    "REBA" | "RULA" | "MMH"
  >();
  const [selectedFrame, setSelectedFrame] = useState<number>();
  const [assessmentDataDto, setAssessmentDataDto] =
    useState<AssessmentDataDto>();
  const [helpMethod, setHelpMethod] = useState<"REBA" | "RULA" | "MMH">();
  const [
    openSelectAssessmentMethodDialog,
    setOpenSelectAssessmentMethodDialog,
  ] = useState(false);

  useEffect(() => {
    if (selectedFrame && assessmentPageIndex === 0) return;
    if (window.scrollY > 400) {
      window.scrollTo({
        top: 400,
        left: 0,
        behavior: "smooth",
      });
    }
  }, [assessmentPageIndex, selectedFrame]);

  const reset = (props?: ResetPropsType) => {
    if (!props) {
      setId(undefined);
      setName(undefined);
      setSelectedMethod(undefined);
      setSelectedFrame(undefined);
      setAssessmentDataDto(undefined);
      setTargetId(undefined);
    } else {
      setId(props.id);
      setName(props.name);
      setSelectedMethod(props.method);
      setSelectedFrame(props.frameIndex);
      setAssessmentDataDto(props.assessmentDataDto);
      setTargetId(props.targetId);
    }
  };

  return (
    <AssessmentContext.Provider
      value={{
        id,
        removeId: () => setId(undefined),
        targetId,
        setTargetId,
        name,
        setName,
        frameIndex: selectedFrame,
        setSelectedFrame,
        assessmentDataDto,
        setAssessmentDataDto,
        reset,
        assessmentPageIndex,
        setAssessmentPageIndex,
        setHelpMethod,
        setOpenSelectAssessmentMethodDialog,
        selectedMethod,
        selectMethod: (method: "REBA" | "RULA" | "MMH") => {
          setSelectedMethod(method);
          setAssessmentPageIndex(1);
        },
      }}
    >
      {children}

      {helpMethod && (
        <HelpDialog
          open={!!helpMethod}
          handleClose={() => setHelpMethod(undefined)}
          method={helpMethod}
        />
      )}

      <SelectAssessmentMethodDialog
        open={openSelectAssessmentMethodDialog}
        handleClose={() => setOpenSelectAssessmentMethodDialog(false)}
      />
    </AssessmentContext.Provider>
  );
};

export default AssessmentContextProvider;
