import { Info } from "@mui/icons-material";
import { Box, Paper, Stack, Tooltip, Typography } from "@mui/material";
import { useContext, useMemo } from "react";
import { TaskContext } from "src/context/TaskContext";
import { TaskPieChart } from "../TaskPieChart";
import { BodyPartState } from "src/app/logic/pose/types";
import { getStateColor, calculateOverallRisk } from "src/app/logic/pose/state";

export const OverallRiskScore = () => {
  const { selectedAngles, setting } = useContext(TaskContext);

  const { overallRisk, safePercentage, cautionPercentage, hazardPercentage } =
    useMemo(
      () => calculateOverallRisk(selectedAngles, setting),
      [selectedAngles, setting],
    );

  const state =
    overallRisk > 66
      ? BodyPartState.HAZARD
      : overallRisk > 33
        ? BodyPartState.CAUTION
        : BodyPartState.SAFE;

  const stateColor = getStateColor(state);

  return (
    <Paper
      sx={{
        minWidth: 300,
        height: 95,
        p: 1,
        pl: 2,
        position: "relative",
        borderLeftWidth: 5,
        borderLeftStyle: "solid",
        borderLeftColor: stateColor[300],
        overflow: "hidden",
        bgcolor: stateColor[50],
      }}
    >
      <Stack direction="row" justifyContent="space-between">
        <Box width={80} height={80}>
          <TaskPieChart
            wholeTaskState={[
              { name: "Safe", value: safePercentage },
              { name: "Caution", value: cautionPercentage },
              { name: "Hazard", value: hazardPercentage },
            ]}
          />
        </Box>
        <Stack alignItems="center" justifyContent="center">
          <Typography textAlign="center" fontWeight={500}>
            Postural Risk Score
          </Typography>
          <Typography textAlign="center" fontWeight={600}>
            {overallRisk + "%" || "-"}
          </Typography>
        </Stack>
        <Tooltip title="Score ranges: 0-33% (Safe), 34-66% (Caution), 67-100% (Hazard). Represents the level of risk.">
          <Info sx={{ fontSize: 20 }} color="disabled" />
        </Tooltip>
      </Stack>
    </Paper>
  );
};
