import React, { FC } from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
} from "@mui/material";

interface CategorySelectProps {
  value: string;
  onChange: (value: string) => void;
  categories?: { name: string; id: string; isSystemCategory: boolean }[];
}

const CategorySelect: FC<CategorySelectProps> = ({
  value,
  onChange,
  categories,
}) => (
  <FormControl fullWidth required>
    <InputLabel id="angleThresholdsLabelId">Angle Thresholds</InputLabel>
    <Select
      required
      labelId="angleThresholdsLabelId"
      id="angleThresholds"
      value={value}
      label="AngleThresholds"
      onChange={(e) => onChange(e.target.value)}
    >
      {categories &&
        [...categories]
          .reverse()
          .map(({ name, id, isSystemCategory }, index) => (
            <MenuItem key={index} value={id}>
              <Typography component="span">{name}</Typography>
              {isSystemCategory && (
                <Typography
                  component="span"
                  color="primary"
                  fontSize={13}
                  fontWeight="bold"
                >
                  &nbsp;(SYSTEM ANGLE THRESHOLDS)
                </Typography>
              )}
            </MenuItem>
          ))}
    </Select>
  </FormControl>
);

export default CategorySelect;
