import { SettingDto } from "src/app/services/generatedApi";

export enum SettingsNamesEnum {
  neck = "Neck",
  back = "Back",
  knee = "Knee",
  rightKnee = "Right knee",
  leftKnee = "Left knee",
  upperArm = "Upper arm",
  rightUpperArm = "Right upper arm",
  leftUpperArm = "Left upper arm",
  lowerArm = "Lower arm",
  rightLowerArm = "Right lower arm",
  leftLowerArm = "Left lower arm",
  wrist = "Wrist",
  rightWrist = "Right wrist",
  leftWrist = "Left wrist",
}

export const settingKeys = [
  "neck",
  "back",
  "leftUpperArm",
  "rightUpperArm",
  "leftLowerArm",
  "rightLowerArm",
  "leftWrist",
  "rightWrist",
  "leftKnee",
  "rightKnee",
] as (keyof SettingDto)[];
