import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Grid,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { toast } from "react-toastify";

import { FC, useState } from "react";
import {
  useTasksControllerUpdateUserTaskMutation,
  useTasksControllerGetUserTagsForCurrentUserQuery,
  TaskRo,
} from "src/app/services/generatedApi";
import AutocompleteWithAdd, {
  nameIdType,
} from "../../../atoms/AutocompleteWithAdd";
import { tagModifier } from "src/components/organisms/addVideo/tagModifier";

type SaveDialogPropsType = {
  task: TaskRo;
  open: boolean;
  handleClose: () => void;
};

export const EditTaskDialog: FC<SaveDialogPropsType> = ({
  task,
  open,
  handleClose,
}) => {
  const { data: allTags } = useTasksControllerGetUserTagsForCurrentUserQuery();

  const [name, setName] = useState<string>(task.name || "");
  const [tag, setTag] = useState<string | nameIdType | null>(task.tag as any);
  const [description, setDescription] = useState<string>(
    task.description || "",
  );

  const [updateTask, { isLoading }] =
    useTasksControllerUpdateUserTaskMutation();

  const save = () => {
    const tagName = tagModifier(tag);
    updateTask({
      taskUpdateDto: { id: task.id, name, tag: tagName || "", description },
    })
      .unwrap()
      .then(() => {
        toast.success("Saved Successfully.");
        handleClose();
      });
  };
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>Save</DialogTitle>
      <DialogContent>
        <Grid container justifyContent="center" alignItems="center" spacing={1}>
          <Grid item xs={12}>
            <Typography>Update task details.</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              autoFocus
              label="Name"
              type="text"
              fullWidth
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <AutocompleteWithAdd
              onChange={setTag}
              options={(allTags as any) || []}
              initValue={tagModifier(tag)}
              label="Tag"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              multiline
              rows={4}
              label="Description"
              fullWidth
              value={description}
              onChange={({ target: { value } }) => setDescription(value)}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>cancel</Button>
        <LoadingButton loading={isLoading} onClick={save} autoFocus>
          Update
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
