import { FC, useState } from "react";
import {
  Grid,
  Button,
  DialogActions,
  DialogContent,
  TextField,
  Dialog,
  DialogTitle,
} from "@mui/material";
import {
  UserLimitRo,
  useUsersUsageLimitControllerSetUserUsageLimitMutation,
} from "src/app/services/generatedApi";
import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

type UpdateUserLimitDialogPropsType = {
  username: string;
  limit: UserLimitRo;
  open: boolean;
  handleClose: () => void;
};

const UpdateUserLimitDialog: FC<UpdateUserLimitDialogPropsType> = ({
  username,
  limit: { count, duration, date },
  open,
  handleClose,
}) => {
  const [currentUserLimit, setCurrentUserLimit] = useState({
    count,
    duration,
    date,
  });

  const [setUserLimit, { isLoading }] =
    useUsersUsageLimitControllerSetUserUsageLimitMutation();

  const submitHandler = () => {
    setUserLimit({ userLimitDto: { username, ...currentUserLimit } })
      .unwrap()
      .then(() => {
        toast.success("User limits successfully updated");
      });
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>User Limits</DialogTitle>
      <DialogContent>
        <form autoComplete="off">
          <Grid container spacing={3} sx={{ mt: 1 }}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Count"
                type="number"
                value={currentUserLimit.count}
                onChange={({ target: { value } }) =>
                  setCurrentUserLimit((prevState) => ({
                    ...prevState,
                    count: value === "" ? undefined : +value,
                  }))
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Date"
                  inputFormat="MM/dd/yyyy"
                  value={
                    currentUserLimit.date
                      ? new Date(currentUserLimit.date)
                      : null
                  }
                  onChange={(date: Date | null) => {
                    let newDate: string | undefined = undefined;
                    try {
                      newDate = date?.toISOString();
                    } catch {}
                    setCurrentUserLimit((prevState) => ({
                      ...prevState,
                      date: newDate,
                    }));
                  }}
                  renderInput={(params: any) => (
                    <TextField fullWidth {...params} />
                  )}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions
        sx={{ pr: { xs: 1, sm: 2, md: 4 }, pb: { xs: 1, sm: 2, md: 3 } }}
      >
        <Button sx={{ width: "100%", maxWidth: "150px" }} onClick={handleClose}>
          Cancel
        </Button>
        <LoadingButton
          loading={isLoading}
          onClick={submitHandler}
          sx={{ width: "100%", maxWidth: "150px" }}
          variant="contained"
          type="submit"
        >
          Update
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateUserLimitDialog;
