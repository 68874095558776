import { SettingDto } from "src/app/services/generatedApi";

export type HumanBodyPartType = {
  part: keyof SettingDto | "any";
  path: string;
};

export const humanBodyParts: HumanBodyPartType[] = [
  {
    part: "any",
    path: "M564,1347a229.16,229.16,0,0,0,6,33c6.5,2.31,19.137,3.94,22,10-3.549,3.82-4.869,9.78-8,14-10.77,14.53-41.6,33.79-67,28-1.679-2.54-4.981-5.03-6-8q-0.5-13.005-1-26c-1.244-5.85-4.56-17.41-3-24,1.841-7.78,7.612-12.64,9-21C533.479,1353.22,548.463,1349.05,564,1347Z",
  },
  {
    part: "any",
    path: "M425,1347q24,3.495,48,7c13.007,15.79,8.893,52.56,3,75-25.934,15.95-74.839-18.51-78-41a126.441,126.441,0,0,1,22-8C421.329,1369.4,424.826,1360.24,425,1347Z",
  },
  {
    part: "any",
    path: "M356,693c7.81,0.964,15.075,4.083,22,6,12.9,3.57,26.377,4.114,39,7l41,4h24c13.213,2.8,41.038,1.811,54-1l24-1c24.994-5.55,52.372-5.33,74-14,2.394,20.451,5,41.506,5,66v20c-2.125,9.913-2.9,22.263-3,33h-2c-7.346,5.1-20.235,3.623-30,6-17.58,4.279-39.259,3.961-58,8H531c-6.652,1.432-19.58,4.319-28,2-3.748-1.032-7.8-4.157-11-6-0.553,3.881-1.249,3.771-3,6-5.722-.7-17.618,1.9-26,0-24.947-5.65-51.677-4.84-75-11-10.982-2.9-26.042-1.773-34-8-4.591-7.3.009-21.586-2-31C346.048,751.1,352.823,718.822,356,693Z",
  },
  {
    part: "any",
    path: "M482,60c34.979-.49,60.789,7.611,69,33q1.5,16.5,3,33c2.8,7.666,10.761,12.838,13,21,5.358,19.528-12.43,45.523-22,50-10.743-16.415-51.41-23.337-77-15-10.289,3.352-17.756,11.223-27,14-4.329-12.106-16.448-17.519-20-32-4.644-18.933,8.687-26.719,13-37,5.567-13.272,1.963-32.7,9-44,5.188-8.331,16.535-15.064,26-19C473.433,62.156,478.383,62.516,482,60Z",
  },
  {
    part: "rightKnee",
    path: "M517,1350q-0.5-30-1-60c-3.112-14.33-1.62-29.83-5-45-9.14-41.02-8.34-96.31,0-136h30l61-6c1.9,14.47,7.837,34.65,4,52q-2.5,19.5-5,39c-7.9,29.52-15.949,58.88-25,87q-4.5,22.005-9,44c-1.508,6.55-.078,13.4-2,19C549.029,1344.51,534.383,1349.62,517,1350Z",
  },
  {
    part: "leftKnee",
    path: "M479,1109q3,17.505,6,35v19c0,18.79.413,37.51-3,53l-2,26c-7.324,32.42-7.8,70.9-7,108-10.731.19-41.852-2.5-48-7-3.43-5.54-1.25-14.72-3-22q-4-19.005-8-38c-8.163-26.84-16.365-54.49-24-82q-2-14.505-4-29c-4.388-19.14-3.683-52.04,2-69C416.433,1108.26,446.594,1108.83,479,1109Z",
  },
  {
    part: "rightKnee",
    path: "M601,1099c-28.576.99-57.074,5.76-89,6v-2c3.372-5.15,2-17.09,2-25,0-22.56-1.535-43.11-8-60h4c6.212-3.72,18.937.76,27-1,22.436-4.9,47.09-2.85,67-10v11c-3.913,6.3-.243,17.77-2,26C598.4,1060.87,600.885,1081.04,601,1099Z",
  },
  {
    part: "leftKnee",
    path: "M483,1018c-1.656,14.3-4.743,31.93-8,47-2.982,13.8,1.755,28,2,40-31.968.27-60.279-5.02-89-6v-2c2.386-3.9,1-14.24,1-20,0-17.32-1.6-32.13-2-48-0.152-6.01-3.39-19.41,0-22,5.475,3.57,13.72,2.22,21,4C430.035,1016.4,457.382,1016.1,483,1018Z",
  },
  {
    part: "rightKnee",
    path: "M505,1013q1-63.993,2-128c-1.066-4.949.339-13.806-1-20q-2-16-4-32c47.616,0.681,94.031-7.245,133-16v7c-4.4,7.427-3.332,33.554-6,44-7.791,30.5-11.284,63.505-19,94-3.424,13.531-1.605,28.409-6,41-12.349.4-25.643,3.4-37,6C548.792,1013.17,525.242,1012.77,505,1013Z",
  },
  {
    part: "leftKnee",
    path: "M485,1013c-18.558.22-38.217-.44-54-4q-8-.5-16-1c-9.183-2.16-19.585-4.6-30-5-1.476-30.842-8.911-62.544-16-90-8.063-31.23-8.354-63.053-15-96h2c8.529,5.852,22.635,4.212,34,7,28.567,7.008,64.717,8.711,98,9v1c-5.545,8.747-1.4,27.842-4,40-2.627,12.281-1,29.956-1,44Q484,965.495,485,1013Z",
  },
  {
    part: "back",
    path: "M616,617c0.475,22.967,10.2,50.291,15,72h-2c-11.987,8.37-44.284,8.072-62,12-9.7,2.151-22.83,1.807-33,4H511c-13.334,2.811-41.006,1.827-54-1-14.44-3.142-27.364.148-41-3-20.249-4.674-41.191-8.608-60-13v-4l13-51c1.457-5.475.212-11.964,3-16,4.174,2.651,10.143.713,16,2,9.142,2.009,21.561,1.973,31,4h15c13.712,2.928,30.328,2,46,2,22.922,0,48.838,2.342,69-2h14Z",
  },
  {
    part: "back",
    path: "M622,525a230.307,230.307,0,0,1-5,39q-0.5,23.5-1,47c-36.641,4.317-74.218,9-117,9H454c-13.848-2.974-29.486.15-44-3l-37-5c0.227-32.917-4.691-59.287-5-89h2c9.568,7.366,31.126,7.544,45,11,24.3,6.054,52.768,7,83,7,10.013,0,22.65.809,31-1l33-2,39-7C607.635,529.15,614.733,526.031,622,525Z",
  },
  {
    part: "rightWrist",
    path: "M902,734c-12.059-1.945-21.654-25.874-27-36-1.608-3.045-7.645-11.433-6-14,11.455-11.536,29.263-18.97,37-34h2c11.51,19.332,31.105,34.835,40,56,6.487,15.434,1.559,39.678-7,49-1.754-.631-0.971-0.193-2-1-8.57-9.343-10.9-25.347-18-36-7.034-10.551-15.566-14.378-29-19v4C896.6,708.936,901.882,723.868,902,734Z",
  },
  {
    part: "rightWrist",
    path: "M879,608c8.7,3.506,13.123,20.208,18,28,1.924,3.073,6.149,5.629,5,9-3.381,9.79-26.76,29.724-37,33v-1c-6.99-10.432-6.468-25.387-15-34C857.976,630.966,874.606,623.183,879,608Z",
  },
  {
    part: "leftWrist",
    path: "M84,651c5.438,1.97,8.18,8.257,12,12,7.993,7.833,17.725,16.761,28,22-2.62,11.085-22.712,48.449-33,50a21.512,21.512,0,0,1-1-7c5.4-7.164,7.46-18.409,11-27H95c-2.814,2.091-7,2.048-10,4-5.981,3.888-12.237,11.932-16,18-4.722,7.614-10.424,31.145-19,33C21.23,711.466,69.885,678.355,84,651Z",
  },
  {
    part: "leftWrist",
    path: "M128,679c-15.706-2.812-28.913-23.33-39-33l1-3c7.616-6.1,16.378-25.2,22-34h1c3.231,9.76,13.215,19.206,20,26,2.975,2.979,8.965,6.208,8,10C137.583,656.236,129.329,665.182,128,679Z",
  },
  {
    part: "rightLowerArm",
    path: "M808,515c7.491,3.138,13.273,14.69,18,21,10.609,14.162,23.721,27.382,34,42,5.479,7.792,9.271,17.461,16,24-5.053,16.036-21.438,24.295-30,37l-1-1c-4.9-3.276-7.367-9.492-12-13-13.966-10.574-27.135-22.579-41-33-7.331-5.51-19.935-12.783-22-23C780.984,561.616,804.1,529.442,808,515Z",
  },
  {
    part: "leftLowerArm",
    path: "M183,516c4.433,3.964,5.787,11.824,9,17,6.964,11.219,20.338,30.946,31,38l-1,1c-11.4,19.017-34.446,31.9-52,45-8.275,6.174-14.681,18.532-24,23-7.968-11.963-27.282-20.8-29-38,11.13-11.114,18.646-27.247,28-40C156.611,546.169,172.177,532.413,183,516Z",
  },
  {
    part: "leftLowerArm",
    path: "M219,470c3.92,4.233,4.87,10.956,8,16,4,6.448,9.351,12.831,14,19,4.9,6.5,16.651,10.528,19,19-9.759,6.54-26.2,31.33-33,42-11.154-5.775-39.091-43.575-39-56Z",
  },
  {
    part: "rightLowerArm",
    path: "M772,469c10.844,7.143,18.425,22.41,26,33,2.234,3.123,7.237,6.333,6,10-3.155,13-18.582,32.959-27,42-2.029,2.179-9.654,12.289-12,10-8.393-6.622-13.621-19.3-20-28-3.911-5.332-10.574-9.224-13-16C744.156,515.69,769.767,483.524,772,469Z",
  },
  {
    part: "leftUpperArm",
    path: "M297,360c3.834,6.382,2.916,21.708,6,29,7.8,18.444,24.511,37.189,42,46v2l-26,30c-17.58,17.608-38,32.864-55,51-1.754-.631-0.971-0.193-2-1-11.821-4.122-24.414-25.323-31-36-2.349-3.809-8.762-11.544-7-16,3.22-9.7,13.388-18.013,19-26,10.664-15.176,19.348-30.8,30-46C280.579,382.184,290.752,371.9,297,360Z",
  },
  {
    part: "rightUpperArm",
    path: "M694,358c7.83,7.628,12.609,17.88,19,27,11.02,15.727,21.762,31.958,33,48,5.932,8.468,20.9,22.592,23,33l-12,23c-7.43,10.633-18.167,18.712-27,28-11.169-3.976-18.106-17.276-27-24-10.569-7.99-18.749-17.749-28-27l-12-11c-5.072-6.721-9.967-14.217-16-20l1-1c4.279-4.48,9.973-7.227,15-11C679.326,410.744,693.959,386.764,694,358Z",
  },
  {
    part: "back",
    path: "M488,315h16c21.054,46.6,41.913,69.409,84,95,10.415,6.333,23.752,14.085,38,16q-1,46.5-2,93h-2c-10.524,8.117-35.6,8.346-51,12-42.694,10.128-114.33,8.339-155-2-15.051-3.826-37.663-4.848-48-13-3.36-5.234-1.134-21.519-1-29-2.19-3.5-1-12.71-1-18q-0.5-20-1-40c13.8-1.8,26.8-10.774,37-17,27.9-17.027,49.1-34.092,67-61C475.69,340.943,485.912,328.93,488,315Z",
  },
  {
    part: "rightUpperArm",
    path: "M597,289c6.534,0.738,11.1,3.923,16,6,14.219,6.026,27.135,10.709,39,19,4.677,3.268,8.538,8.619,13,12,8.321,6.305,18.171,14.516,22,25,3.906,10.694-1.025,23.922-4,31-7.85,18.675-16.639,35.175-33,45-9.011,5.411-33.619-9.36-43-13-47.031-18.249-77.773-51.969-98-97C533.825,315.952,584.476,301.5,597,289Z",
  },
  {
    part: "leftUpperArm",
    path: "M393,290c8.26,2.076,15.465,8.716,23,12,12.069,5.26,26.089,8.392,39,12,6.616,1.849,25.172,1.528,28,6-6.012,6.4-8.283,17.491-13,25-11.58,18.433-28.5,37.443-46,50a184.4,184.4,0,0,1-35,20c-12.915,5.419-27.69,7.129-37,16-26.043-5.559-62.59-59.272-41-89,12.821-17.653,38.472-32.816,59-43C377.108,295.474,386.871,294.738,393,290Z",
  },
  {
    part: "neck",
    path: "M480,184c21.256-.575,55.915,3.064,62,15v26l6,26c7.4,17.529,20.479,31.492,41,36l-1,1c-27.563,26.759-114.241,27.96-157,14-10.949-3.575-25.555-5.953-32-14,25.058-6.164,42.085-27.325,48-53,2.7-11.736-1.468-24.3-2-34,4.839-3.1,8.526-8.5,14-11C465.089,187.22,474.353,187.766,480,184Z",
  },
];
